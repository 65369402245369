import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import c from 'classnames';
import { getStaticText } from '../../../utils/constants';
import { BUILD_REPORT_OPTION, BUILD_REPORT_PAYLOAD } from '../constants';
import './index.scss';

function BuildReport({ defaultSettings, goBack, submitReport, reportApiParams, reportIndex }) {
    const { lang: defaultLang, ohi_text } = defaultSettings;
    const staticText = getStaticText(defaultLang);
    const { SITE_TEXT } = staticText;
    const { BACK, NEXT, DYNAMIC_TEXT_INFO } = SITE_TEXT || {};
    const { REPORT_TYPE } = DYNAMIC_TEXT_INFO({ ohi_text });
    const { build_your_report = BUILD_REPORT_PAYLOAD } = reportApiParams || {};
    const [payload, setPayload] = useState(build_your_report);

    const isActive = Object.keys(payload).filter(key => payload[key].keep).length > 0;

    const handleNext = () => {
        reportApiParams.build_your_report = payload;
        submitReport(reportApiParams);
    };

    return (
        <div data-testid="BuildReportsComponent" className="buildReportsComponent">
            <div className="generalDemoSection">
                <h2>{REPORT_TYPE[reportIndex]} - Build your report</h2>
                <span className="title">
                    You can customize your report download by removing any of the sections in the list. All sections are
                    selected by default which will produce a standard full deck output
                </span>
            </div>
            <section className="generalSection">
                <div className="optionWrappered">
                    {BUILD_REPORT_OPTION.map(({ key = '', children = [], label, disabled_ids }) => {
                        const { keep } = payload[key] || {};
                        if (children.length) {
                            return (
                                <Fragment key={label}>
                                    <div className="sectionLabel">{label}</div>
                                    {children.map(({ key: innerKey, label: innerLabel, disabled_ids: disabledIds }) => {
                                        const { keep: innerKeep } = payload[innerKey];
                                        return (
                                            <FormControlLabel
                                                classes={{
                                                    root: 'innerReportCheckbox',
                                                }}
                                                key={innerKey}
                                                disabled={disabledIds.includes(reportIndex)}
                                                control={
                                                    <Checkbox
                                                        // name="includeOhi4Demo"
                                                        checked={innerKeep}
                                                        onChange={({ target }) => {
                                                            const { checked: value } = target;
                                                            setPayload({
                                                                ...payload,
                                                                [innerKey]: {
                                                                    keep: value,
                                                                },
                                                            });
                                                            // handleP;
                                                        }}
                                                    />
                                                }
                                                label={<span>{innerLabel}</span>}
                                                labelPlacement="end"
                                            />
                                        );
                                    })}
                                </Fragment>
                            );
                        }
                        return (
                            <FormControlLabel
                                classes={{
                                    root: 'reportCheckbox',
                                }}
                                key={key}
                                disabled={disabled_ids.includes(reportIndex)}
                                control={
                                    <Checkbox
                                        name="includeOhi4Demo"
                                        checked={keep}
                                        onChange={({ target }) => {
                                            const { checked: value } = target;
                                            setPayload({
                                                ...payload,
                                                [key]: {
                                                    keep: value,
                                                },
                                            });
                                        }}
                                    />
                                }
                                label={<span>{label}</span>}
                                labelPlacement="end"
                            />
                        );
                    })}
                </div>
                <div className="buttonWrapperReports">
                    <div className="outlineBtn" onClick={goBack}>
                        {BACK}
                    </div>
                    <div className={c('blueButton', { active: isActive })} onClick={handleNext}>
                        {NEXT}
                    </div>
                </div>
            </section>
        </div>
    );
}

BuildReport.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    goBack: PropTypes.func.isRequired,
    submitReport: PropTypes.func.isRequired,
    reportApiParams: PropTypes.object.isRequired,
    reportIndex: PropTypes.number.isRequired,
};

export default BuildReport;
