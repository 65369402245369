import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Select, MenuItem } from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import { cloneDeep, reverse } from 'lodash';
import { getStaticText } from '../../../utils/constants';
import getStaticTextDiagnose from '../constants';
import FilterSvg from '../assets/Filter.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import Questions from './Questions';
import IndExpScorecard from './IndExpScorecard';
import './index.scss';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import HeatmapBoard from './HeatmapBoard';
import DeepDiveBoard from './DeepDiveBoard';
import Down from '../assets/Down.svg';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import { getBenchmarkName } from '../../../utils/functions';

import colors from '../../../sass/colors';

function IndExperience({
    defaultSettings,
    singleQuestion,
    singleQuestionFetched,
    getSingleQuestion,
    apiParams,
    apiLoadingCount,
    errorInfo,
    setSelectedDemographic,
    getIeHeatmap,
    selectedDemographics,
    filters,
    activeResetFilter,
    toggleFilterView,
    reportError,
    getReport,
    getRawPptData,
    getSinglePastReport,
    singlePastReport,
    appliedFiltersInfo,
    addOnTab,
    setAddOnTabs,
}) {
    const {
        threshold = 10,
        demographic = '',
        report_id = '',
        checkme_mapping = {},
        report_name_or_client_name: rName = '',
        lang: defaultlang,
        ohid: LatamOhid = '',
        role: LatamRole = '',
        engagement = '',
        quartileColors = [],
    } = defaultSettings;

    const { ohid = '', filters: filterParams = [], report_type = '', bm_filters, benchmarks, year, lang } =
        apiParams || {};
    const staticTextDiagnose = getStaticTextDiagnose(defaultlang);
    const { IE_CROSSTAB_OPTIONS, ORGHEALTH_DEMO, PRACTICE_VAR } = staticTextDiagnose;
    const staticText = getStaticText(defaultlang);
    const { SINGLE_PAGE_EXPORT_PARAMS, SITE_TEXT, NON_PROFIT_SURVEY, IND_EXP_SCORE_CLASSIFICATION } = staticText;
    const {
        IND_EXP_LINKS,
        FILTERS,
        DOWNLOAD,
        NUMBER_OF_RESPONDENTS_N,
        EMPLYEE_EXPEIENCE,
        IND_QUES_LEGEND,
        FREQUENCY_LEVEL_PERCENT,
        EMPLOYEE_FACTORS_POSITIVE,
        EMPLOYEE_FACTORS_NEGATIVE,
        BENCHMARK_HEAD,
        BENCHMARK_NA,
    } = SITE_TEXT || {};
    // const [addOnTab, setAddOnTabs] = useState(0);
    const DEFAULT_STATE = 'demo_OHIScoreCal';
    const NON_PROFIT_DEFAULT_STATE = 'demo_IENPS1';
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const defaultStateCode = isNonProfit ? NON_PROFIT_DEFAULT_STATE : DEFAULT_STATE;
    const [crossTabDemo, setCrossTabDemo] = useState(defaultStateCode);
    const [selectedTab, setSelectedTab] = useState(0);
    const [dwnld, setdwnld] = useState(false);

    const { qbyq: singleQbyq = {} } = singleQuestion || {};
    const { records: data = {}, size = '' } = singleQbyq || {};
    const legends = reverse(cloneDeep(IND_EXP_SCORE_CLASSIFICATION));

    const addOns = {
        fill_threshold_with: demographic === '1' ? '-' : '',
        others: demographic === '3' ? 1 : 0,
    };

    const filterCheck = apiParams.filters || [];
    const RoleClient = LatamRole.toLowerCase();
    const LatamHide = !!(LatamOhid === 'OHI13648' && RoleClient === 'roles.client');
    const ex = {
        report_type,
        bm_filters,
        threshold,
        lang,
        ohid,
        benchmarks,
        filters: filterParams,
        year,
    };

    const callHeatMap = code => {
        getIeHeatmap({
            demographic: code,
            threshold,
            lang,
            page: 1,
            ohid,
            benchmarks,
            type: PRACTICE_VAR,
            filters: filterParams,
            year,
            columns: 50,
            ...addOns,
        });
    };

    useEffect(() => {
        const { demographics = [] } = filters[0] || {};
        if (!singleQuestionFetched) {
            getSingleQuestion(ex);
        }
        if (demographics.length) {
            const { code = '' } = demographics[0] || {};
            setSelectedDemographic(code);
        }

        // eslint-disable-next-line
    }, [singleQuestionFetched]);

    const newDeepDiving = IE_CROSSTAB_OPTIONS[3].code === crossTabDemo;
    const newDeepDiv = IE_CROSSTAB_OPTIONS[4].code === crossTabDemo;

    const newDeepDiveNote1 = IE_CROSSTAB_OPTIONS[0].code === crossTabDemo;
    const newDeepDiveNote2 = IE_CROSSTAB_OPTIONS[1].code === crossTabDemo;
    const newDeepDiveNote3 = IE_CROSSTAB_OPTIONS[2].code === crossTabDemo;

    const newDeepDive = newDeepDiveNote1 || newDeepDiveNote2 || newDeepDiveNote3;

    const getPage = () => {
        switch (addOnTab) {
            case 0:
                return (
                    <IndExpScorecard
                        qbyqData={singleQbyq}
                        defaultSettings={defaultSettings}
                        apiLoadingCount={apiLoadingCount}
                    />
                );
            case 1:
                return <Questions data={data} defaultSettings={defaultSettings} apiLoadingCount={apiLoadingCount} />;
            case 2:
                return <HeatmapBoard callHeatMap={callHeatMap} apiLoadingCount={apiLoadingCount} />;
            case 3:
                return (
                    <DeepDiveBoard
                        crossTabDemo={crossTabDemo}
                        setCrossTabDemo={setCrossTabDemo}
                        callHeatMap={callHeatMap}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        apiLoadingCount={apiLoadingCount}
                        newDeepDiving={newDeepDiving}
                        newDeepDiv={newDeepDiv}
                        newDeepDive={newDeepDive}
                        addOnTab={addOnTab}
                    />
                );
            default:
                return <></>;
        }
    };

    const renderRightOptions = () => {
        const { demographics = [] } = filters[0] || {};
        if (addOnTab === 1) {
            return (
                <div className="outcomeContent">
                    <ul className="questionLegends">
                        <li>{FREQUENCY_LEVEL_PERCENT}</li>
                        {IND_QUES_LEGEND.map(legendText => (
                            <li>
                                <span />
                                {legendText}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        if (addOnTab < 2 || (addOnTab === 3 && crossTabDemo !== DEFAULT_STATE)) return null;
        return (
            <div className="rightOptions">
                <span>
                    <Select
                        IconComponent={KeyboardArrowDownSharpIcon}
                        value={selectedDemographics}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        onChange={({ target: { value: code } }) => {
                            setSelectedDemographic(code);
                            callHeatMap(code);
                        }}
                    >
                        {demographics.map(({ label, code }) => {
                            return (
                                <MenuItem classes={{ root: 'demographicOptions' }} key={code} value={code}>
                                    {label}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </span>
            </div>
        );
    };

    const getHeatmapDemographic = () => {
        const { demographics = [] } = filters[0] || {};
        const map = new Map();
        demographics.forEach(({ code, label }) => {
            if (selectedDemographics === code) {
                map.set(code, label);
            }
        });
        return Object.fromEntries(map);
    };

    const getMeta = () => {
        const deepDiveIndex = IE_CROSSTAB_OPTIONS.findIndex(({ code }) => code === crossTabDemo) || 0;
        const { employee_experience = [] } = checkme_mapping || {};
        const getCategoryCheckme = () => {
            const arr = employee_experience.slice(3, 6);
            switch (selectedTab) {
                case 0:
                    return arr[1];
                case 1:
                    return arr[0];
                case 2:
                    return arr[2];
                default:
                    return arr;
            }
        };
        const deepDiveCheckme =
            deepDiveIndex === 0 ? getCategoryCheckme() : employee_experience.slice(6)[deepDiveIndex - 1];
        const checkmeInUse = crossTabDemo === ORGHEALTH_DEMO ? 'Employee_Experience_with_OrgHealth' : deepDiveCheckme;
        switch (addOnTab) {
            case 0:
                return {
                    checkme: [employee_experience[0]],
                    name: 'Employee_Experience_Scorecard',
                };
            case 1:
                return {
                    checkme: [employee_experience[1]],
                    name: 'Employee_Experience_qbyq',
                };
            case 2:
                return {
                    checkme: [employee_experience[2]],
                    name: 'Employee_Experience_heatmap',
                };
            case 3:
                return {
                    checkme: [checkmeInUse],
                    name: checkmeInUse,
                };
            default:
                return {};
        }
    };

    const pptDownload = () => {
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...apiParams,
            report_id,
            report_name: rName,
            ...addOns,
            employee_experience_demographic: getHeatmapDemographic(),
            single_page_meta: getMeta(),
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    return (
        <>
            <div className="scoreBoard addOns clearfix">
                <div className="indExpBoard">
                    <div className="rightScorecardHeader">
                        <div className="scorecardHeading">
                            <div className="mainHeading">{EMPLYEE_EXPEIENCE}</div>
                            <div className="nRes">
                                {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                            </div>
                        </div>
                        <ul className="navScoreList">
                            {!errorInfo && (
                                <li data-testid="getPptBtn" onClick={pptDownload} className="resurveyImg">
                                    <img src={Down} alt="" />
                                    <span className="lineHead upperHead">{DOWNLOAD}</span>
                                </li>
                            )}
                            <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                <img
                                    src={activeResetFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg}
                                    alt=""
                                />
                                <span className="filter">{FILTERS}</span>
                            </li>
                        </ul>
                    </div>
                    {appliedFiltersInfo()}

                    <div className={`optionWrapper ${addOnTab === 1 ? 'stickyTop' : ''}`}>
                        <div>
                            <ul className="leftNav">
                                {IND_EXP_LINKS.map((tabName, index) => {
                                    return (
                                        <li
                                            data-testid={tabName}
                                            key={tabName}
                                            className={c({ activeLink: addOnTab === index })}
                                            onClick={() => setAddOnTabs(index)}
                                        >
                                            {tabName}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        {renderRightOptions()}
                        {/* <div className="rightOptions">
                        <ul className="rightNav">
                            <li className="strong">
                                {TOTAL_RES_TEXT} {size}
                            </li>
                            <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                                <img src={activeFilter ? ActiveFilter : FilterSvg} alt="" />
                                {FILTERS}
                            </li>
                        </ul>
                    </div> */}
                    </div>
                    {dwnld && !reportError && (
                        <DwnldModal
                            getRawPptData={getRawPptData}
                            reportId={report_id}
                            reportError={reportError}
                            open={dwnld}
                            setOpen={setdwnld}
                            getSinglePastReport={getSinglePastReport}
                            singlePastReport={singlePastReport}
                            LatamHide={LatamHide}
                            defaultSettings={defaultSettings}
                        />
                    )}
                    {!errorInfo && getPage()}
                    {errorInfo ? <ErrorToast message={errorInfo} /> : null}
                </div>
            </div>
            {addOnTab !== 2 && (
                <ul className="fixedScoreCardSurvey">
                    {addOnTab === 3 && (
                        <ul className="benchmark clearfix">
                            <li>{BENCHMARK_HEAD} : </li>
                            {quartileColors
                                .slice()
                                .reverse()
                                .map(({ title, background, border: borderColor }) => {
                                    const bottomDecileVar = title === 'Bottom decile';
                                    const BenchmarkTitle = title === 'No benchmarks';
                                    return (
                                        <li
                                            key={title}
                                            style={{ color: background }}
                                            className={c({
                                                hide: bottomDecileVar || BenchmarkTitle,
                                            })}
                                        >
                                            <span
                                                className="benchmarkTriangle"
                                                style={{
                                                    background,
                                                    borderWidth: '13px 13px 0 0',
                                                    borderColor: bottomDecileVar
                                                        ? `transparent ${borderColor} transparent transparent`
                                                        : `${borderColor} transparent transparent transparent`,
                                                    borderStyle: 'solid',
                                                }}
                                            />
                                            <div style={{ color: 'black' }}>{title}</div>
                                        </li>
                                    );
                                })}
                            <li>
                                <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                                {BENCHMARK_NA}
                            </li>
                        </ul>
                    )}
                    <ul className="benchmark clearfix">
                        <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                        {legends.map(({ title, positiveBg: background }) => (
                            <li key={title}>
                                <span style={{ background, border: 'none' }} />
                                {title}
                            </li>
                        ))}
                    </ul>
                    <ul className="benchmark clearfix">
                        <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                        {legends.map(({ title, negativeBg: background }) => (
                            <li key={title}>
                                <span style={{ background, border: 'none' }} />
                                {title}
                            </li>
                        ))}
                    </ul>
                </ul>
            )}

            {addOnTab === 2 && (
                <ul className="fixedScoreCardSurvey">
                    <ul className="benchmark clearfix">
                        <li>{EMPLOYEE_FACTORS_POSITIVE} : </li>
                        {IND_EXP_SCORE_CLASSIFICATION.map(({ title, positiveBg: background }) => (
                            <li key={title}>
                                <span style={{ background, border: 'none' }} />
                                {title}
                            </li>
                        ))}
                    </ul>
                    <ul className="benchmark clearfix">
                        <li>{EMPLOYEE_FACTORS_NEGATIVE} : </li>
                        {IND_EXP_SCORE_CLASSIFICATION.map(({ title, negativeBg: background }) => (
                            <li key={title}>
                                <span style={{ background, border: 'none' }} />
                                {title}
                            </li>
                        ))}
                    </ul>
                </ul>
            )}
        </>
    );
}

IndExperience.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    singleQuestion: PropTypes.array.isRequired,
    singleQuestionFetched: PropTypes.bool.isRequired,
    getSingleQuestion: PropTypes.func.isRequired,
    apiParams: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    setSelectedDemographic: PropTypes.func.isRequired,
    getIeHeatmap: PropTypes.func.isRequired,
    selectedDemographics: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    errorInfo: PropTypes.number.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    addOnTab: PropTypes.number.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
};

export default IndExperience;
