import React, { useEffect, useState, useMemo, Fragment } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { getStaticText } from '../../../../utils/constants';
import getStaticTextDiagnose from '../../constants';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import ArrowRight from '../../assets/ArrowRight.svg';
import ArrowUp from '../../assets/ArrowUp.svg';
import ArrowDown from '../../assets/ArrowDown.svg';
import { getColor, manipulateHeatmapTitle } from '../../../../utils/functions';
import colors from '../../../../sass/colors';

function HeatmapBoard({
    filters,
    ieHeatmap,
    ieHeatmapFetched,
    sortIeHeatmap,
    callHeatMap,
    defaultSettings,
    apiLoadingCount,
    errorInfo,
}) {
    const { lang = '1033', report_type: surveyType = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const {
        MAX_LENGTH_SUBSTRING,
        SORT_ASCENDING,
        SORT_DESCENDING,
        QUESTIONS_HEATMAP,
        SLICE_IE_MAX_VALUE,
        SLICE_IE_MIN_VALUE,
    } = staticTextDiagnose;
    const { SITE_TEXT, IND_EXP_SCORE_CLASSIFICATION } = staticText;
    const {
        N_SIZE,
        OHI_SCORE_TEXT,
        NUMBER_CAP_TEXT,
        NEGATIVE_PRACTICES,
        EE_PERCENT_FAVORABLE,
        EE_PERCENTILE,
        NOTE,
        IND_EXP_QUESTION_NOTE,
    } = SITE_TEXT;
    const { demographics = [] } = filters[0] || {};
    const { header = [], items = [] } = ieHeatmap || {};
    const [minSliceValue, setMinSliceValue] = useState(SLICE_IE_MIN_VALUE);
    const [maxSliceValue, setMaxSliceValue] = useState(SLICE_IE_MAX_VALUE);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [sortOrder, setSortOrder] = useState({});

    const headerInclusion = useMemo(() => {
        if (!items.length) return [];
        return [...items.map(({ title = '' }) => title)];
        // eslint-disable-next-line
    }, [ieHeatmap]);

    const dataToUse = useMemo(() => {
        if (!items.length) return [];
        const newArray = [];
        const innerArray = [];
        items.forEach(({ items: subItem = [] }) => {
            innerArray.push(subItem);
        });
        // eslint-disable-next-line no-unused-vars
        header.forEach(i => {
            newArray.push([]);
        });
        for (let i = 0; i < innerArray.length; i += 1) {
            // const { items: subItems } = items
            for (let j = 0; j < header.length; j += 1) {
                newArray[j].push(innerArray[i][j]);
            }
        }

        return newArray;
        // eslint-disable-next-line
    }, [ieHeatmap, maxSliceValue, minSliceValue]);

    const headerToUse = headerInclusion;

    const getHeatMapValues = arrItems => {
        return QUESTIONS_HEATMAP.map(({ children: child = [], title: categoryN = '' }, dataI) => {
            const itemsToIterate = [];
            const titleArr = [];
            child.forEach(elem => {
                const index = header.indexOf(elem);
                itemsToIterate.push(arrItems[index]);
                titleArr.push(header[index]);
            });
            const showCategoryI = dataI === 0 ? 1 : 0;
            return itemsToIterate.map((subItems, parentI) => {
                const title = manipulateHeatmapTitle(titleArr[parentI]);
                const isN = titleArr[parentI] === NUMBER_CAP_TEXT;
                const isNegativeTrait = NEGATIVE_PRACTICES.includes(title);
                return (
                    <div className="sectionDiv" key={title}>
                        {showCategoryI === parentI ? (
                            <ul>
                                <li className="sectionContent headerContent">{categoryN}</li>
                            </ul>
                        ) : null}
                        <ul>
                            <Tooltip placement="top" arrow title={title} aria-label={title}>
                                <li className="sectionContent">{title}</li>
                            </Tooltip>
                            {subItems.slice(minSliceValue, maxSliceValue).map(({ score: innerScore }, index) => {
                                const i = getColor(innerScore, [], false, true);
                                const { positiveBg = '#ffffff', negativeBg = '#ffffff', color: textColor = '#ffffff' } =
                                    IND_EXP_SCORE_CLASSIFICATION[i] || {};
                                const bg = isNegativeTrait ? negativeBg : positiveBg;
                                const background = !isN ? bg : '#ffffff';
                                const color = !isN ? textColor : colors.$darkBlue200;
                                return (
                                    <li
                                        className={c({ nSize: !i }, 'contentList')}
                                        key={`${innerScore}${index}${Math.random()}`}
                                        style={{
                                            border: `1px solid white`,
                                            background,
                                            color,
                                        }}
                                    >
                                        {!isNaN(parseInt(innerScore, 10)) ? innerScore.toLocaleString('en-US') : '-'}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            });
        });
    };

    const sortData = type => {
        if (type === N_SIZE || type === OHI_SCORE_TEXT) {
            setSortOrder({ [type]: sortOrder[type] === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING });
            const arr = [];
            items.map(({ items: subItems = [] }, i) => {
                const { score = '' } = type === N_SIZE ? subItems[0] || {} : subItems[1] || {};
                arr.push(score + '#' + i);
                return items;
            });
            const sortArr =
                sortOrder[type] === 1
                    ? arr.slice(1, arr.length).sort((a, b) => a.split('#')[0] - b.split('#')[0])
                    : arr.slice(1, arr.length).sort((a, b) => b.split('#')[0] - a.split('#')[0]);
            sortArr.unshift(arr[0]);
            const indexArr = [];
            sortArr.map(val => {
                return indexArr.push(arr.indexOf(val));
            });
            const output = indexArr.map(i => items[i]);
            sortIeHeatmap(output);
        }
    };

    useEffect(() => {
        if (!ieHeatmapFetched) {
            callHeatMap(demographics[0].code);
        }
        // eslint-disable-next-line
    }, [ieHeatmapFetched, maxSliceValue]);

    useEffect(() => {
        if (items.length - 1 >= maxSliceValue) {
            setShowRightArrow(true);
        } else {
            setShowRightArrow(false);
        }
        // eslint-disable-next-line
    }, [maxSliceValue, items]);
    return (
        <Fragment>
            <div className="demographicBoard clearfix clearPadding">
                <section className="sectionWrapper">
                    <div id="myHeader" className={c('headerWrapper clearfix')}>
                        {showLeftArrow ? (
                            <div
                                data-testid="arrowLeft"
                                className="arrow"
                                onClick={() => {
                                    setMaxSliceValue(minSliceValue);
                                    setMinSliceValue(minSliceValue - (SLICE_IE_MAX_VALUE - SLICE_IE_MIN_VALUE));
                                    setShowRightArrow(maxSliceValue >= SLICE_IE_MAX_VALUE);
                                    setShowLeftArrow(minSliceValue > SLICE_IE_MAX_VALUE);
                                }}
                            >
                                <img className="imgClass" src={ArrowLeft} alt="" />
                            </div>
                        ) : null}

                        <div>
                            {headerToUse.length ? (
                                <ul className={c({ tabLeft: true })}>
                                    {headerToUse &&
                                        headerToUse.slice(minSliceValue, maxSliceValue).map((val, i) => {
                                            const cellVal = manipulateHeatmapTitle(val);
                                            return (
                                                <Tooltip
                                                    key={`${val}${i}`}
                                                    placement="top"
                                                    arrow
                                                    title={val}
                                                    aria-label={val}
                                                >
                                                    <li className="headerList" onClick={() => sortData(val)}>
                                                        {cellVal.length > MAX_LENGTH_SUBSTRING
                                                            ? `${cellVal.substring(0, MAX_LENGTH_SUBSTRING) + '...'}`
                                                            : cellVal}
                                                        {cellVal === OHI_SCORE_TEXT && (
                                                            <img
                                                                className="arrowSort"
                                                                src={sortOrder[val] === 1 ? ArrowUp : ArrowDown}
                                                                alt=""
                                                            />
                                                        )}
                                                    </li>
                                                </Tooltip>
                                            );
                                        })}
                                </ul>
                            ) : null}
                        </div>
                        {showRightArrow ? (
                            <div
                                data-testid="arrowRight"
                                className="arrow arrowRight"
                                onClick={() => {
                                    setMinSliceValue(maxSliceValue);
                                    setMaxSliceValue(maxSliceValue + SLICE_IE_MAX_VALUE);
                                    setShowRightArrow(maxSliceValue + SLICE_IE_MAX_VALUE <= header.length);
                                    setShowLeftArrow(maxSliceValue + SLICE_IE_MAX_VALUE > SLICE_IE_MAX_VALUE);
                                }}
                            >
                                <img className="imgClass" src={ArrowRight} alt="" />
                            </div>
                        ) : null}
                    </div>
                    {/* {status === 400 || (heatMapInclusionError && isInclusion) ? (
                    <ErrorToast message={message || heatMapInclusionError} />
                ) : null} */}
                    <div className="contentWrapper clearfix">
                        <div className="mainContent">{dataToUse.length ? getHeatMapValues(dataToUse) : null}</div>
                    </div>
                </section>
            </div>
            {!apiLoadingCount && !errorInfo && (
                <div className="note">
                    <b>{NOTE}:</b>
                    <br />
                    {IND_EXP_QUESTION_NOTE[0]}
                    <br />
                    {IND_EXP_QUESTION_NOTE[1]}
                    <br />
                    {surveyType === 'percent favorable' ? <div>3. {EE_PERCENT_FAVORABLE}</div> : ''}
                    {surveyType === 'percentile' ? <div>3. {EE_PERCENTILE}</div> : ''}
                </div>
            )}
        </Fragment>
    );
}

HeatmapBoard.propTypes = {
    filters: PropTypes.array.isRequired,
    ieHeatmap: PropTypes.object.isRequired,
    ieHeatmapFetched: PropTypes.bool.isRequired,
    callHeatMap: PropTypes.func.isRequired,
    sortIeHeatmap: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    errorInfo: PropTypes.number.isRequired,
};

export default HeatmapBoard;
