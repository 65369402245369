import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { ArrowBack } from '@material-ui/icons';
import './index.scss';
import moment from 'moment';
import { Button, Input, TextField } from '@material-ui/core';
import ImageModal from './ImageModal';

function FeedbackDetail({
    defaultSettings,
    ticketId,
    setView,
    getFeedbackDetails,
    feedbackDetails,
    refreshFeedbackList,
}) {
    const { user_id } = defaultSettings || {};
    const {
        // created_on = '',
        type_of_bug = '',
        summary = '',
        status = '',
        description,
        is_workaround = false,
        immediate_impact = false,
        p_url = [],
        ohid,
        created_on = '',
        user_name = '',
        user_email = '',
        report_name = '',
        other_detail: system_detail = '',
        jira_number = '',
    } = feedbackDetails || {};

    const [modal, setModal] = useState(0);

    useEffect(() => {
        getFeedbackDetails({ user_id, feedback_id: ticketId });
        // eslint-disable-next-line
    }, []);

    const refreshList = () => {
        refreshFeedbackList({ feedback_id: ticketId, user_id });
    };

    return (
        <div className="feedbackDetails">
            {/* <div className="breadCrumb">
                <span data-testid="setScoreFromBuBtn" onClick={() => setView(1)}>
                    My Feedback List
                </span>
                {'   '}
                {'  >  '} {summary}
            </div> */}
            <h2 className="clearfix">
                <span onClick={() => setView(1)} className="arrowIcon">
                    <ArrowBack />
                </span>
                {`${summary}`}
                <span className="nRes">
                    Ticket ID : <b>{jira_number}</b>
                </span>
                <div>
                    <Button
                        onClick={refreshList}
                        className="action-btn"
                        startIcon={
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <path
                                    d="M9.7508 2.69891C8.23407 1.14961 5.87274 0.666337 3.83016 1.59384C1.45172 2.67385 0.229074 5.33818 0.961253 7.84564C1.69343 10.3531 4.15768 11.9409 6.74358 11.5713C9.32947 11.2017 11.2503 8.98724 11.2508 6.37507C11.2508 6.16796 11.083 6.00004 10.8759 6C10.6688 5.99996 10.5008 6.16782 10.5008 6.37493C10.5004 8.61393 8.85395 10.512 6.63747 10.8288C4.42098 11.1456 2.30877 9.78467 1.68119 7.63542C1.05361 5.48617 2.10159 3.20246 4.14025 2.27673C5.95578 1.45233 8.0651 1.92825 9.3563 3.375H7.1258C6.91869 3.375 6.7508 3.54289 6.7508 3.75C6.7508 3.95711 6.91869 4.125 7.1258 4.125H10.1037C10.1077 4.12506 10.1116 4.12506 10.1156 4.125H10.1258C10.3329 4.125 10.5008 3.95711 10.5008 3.75V0.75C10.5008 0.542893 10.3329 0.375 10.1258 0.375C9.91869 0.375 9.7508 0.542893 9.7508 0.75V2.69891Z"
                                    fill="currentcolor"
                                />
                            </svg>
                        }
                    >
                        Refresh
                    </Button>
                </div>
            </h2>
            <div className="scrollSection">
                <form>
                    <div>
                        <div>
                            <div className="title">Type of Issue</div>
                            <Input
                                // placeholder="Placeholder"
                                type="text"
                                name="summary"
                                // onChange={handleChange}
                                value={type_of_bug}
                                disabled
                            />
                        </div>
                        <div>
                            <div className="title">Status</div>
                            <div className="status">
                                <span
                                    className={c({
                                        todo: status === 'To Do',
                                        done: status === 'Done',
                                    })}
                                >
                                    {status}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">Issue Description</div>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                inputProps={{
                                    'data-testid': 'description',
                                }}
                                name="description"
                                value={description}
                                disabled
                            />
                        </div>
                        <div>
                            <div className="title">Added Image</div>
                            <div className="imagePreview">
                                {p_url.map((url, i) => {
                                    return (
                                        <span className="imageContainer" onClick={() => setModal(i + 1)}>
                                            <img src={url} alt="" onClick={() => setModal(i + 1)} />{' '}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">
                                Will there be an immediate impact on the ability to serve client?
                            </div>
                            <Input
                                type="text"
                                id="workaround_yes"
                                name="is_workaround"
                                value={immediate_impact}
                                disabled

                                // onChange={handleChange}
                            />
                            <div />
                        </div>
                        <div>
                            <div className="title">Is there an workaround that you can do for now?</div>
                            <Input
                                type="text"
                                id="workaround_yes"
                                name="is_workaround"
                                value={is_workaround}
                                disabled

                                // onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">Username</div>
                            <Input
                                type="text"
                                name="is_workaround"
                                value={user_name}
                                disabled

                                // onChange={handleChange}
                            />
                            <div />
                        </div>
                        <div>
                            <div className="title">User email ID</div>
                            <Input
                                type="text"
                                // id="workaround_yes"
                                // name="is_workaround"
                                value={user_email}
                                disabled

                                // onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">Report name</div>
                            <Input
                                type="text"
                                id="workaround_yes"
                                // name="is_workaround"
                                value={report_name}
                                disabled

                                // onChange={handleChange}
                            />
                            <div />
                        </div>
                        <div>
                            <div className="title">Report OHI ID</div>
                            <Input
                                type="text"
                                id="workaround_yes"
                                name="is_workaround"
                                value={ohid}
                                disabled

                                // onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="title">System Details</div>
                            <Input
                                type="text"
                                // id="workaround_yes"
                                // name="is_workaround"
                                value={system_detail}
                                disabled

                                // onChange={handleChange}
                            />
                            <div />
                        </div>
                        <div>
                            <div className="title">Date & time</div>
                            <Input
                                type="text"
                                // id="workaround_yes"
                                // name="is_workaround"
                                value={moment(created_on).format('MMMM Do YYYY, h:mm:ss a')}
                                disabled

                                // onChange={handleChange}
                            />
                        </div>
                    </div>
                </form>
                <div className="note">
                    <div>
                        <b>Note:</b>
                    </div>
                    <div>Please reach out to OHI-Helpdesk team at any point if you have any questions.</div>
                </div>
            </div>
            {modal > 0 ? (
                <ImageModal defaultSettings={defaultSettings} imageArr={p_url} closeModal={() => setModal(0)} />
            ) : null}
        </div>
    );
}

// Feedback.defaultProps = {
//     showBenchmarks: true,
// };

FeedbackDetail.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    ticketId: PropTypes.number.isRequired,
    setView: PropTypes.func.isRequired,
    getFeedbackDetails: PropTypes.func.isRequired,
    feedbackDetails: PropTypes.func.isRequired,
    refreshFeedbackList: PropTypes.func.isRequired,
};

export default FeedbackDetail;
