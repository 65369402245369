import * as lang_1033 from './Language/lang_1033';
import * as lang_10 from './Language/lang_10';
import * as lang_21 from './Language/lang_21';
import View from './assets/view.svg';
import Edit from './assets/Edit.svg';
import Upload from './assets/upload.svg';
import Trash from './assets/trash.svg';
import Draft from './assets/D-edit.svg';
import Clock from './assets/Clock.svg';
import Duplicate from './assets/paper.svg';

export const getStaticTextAnalytics = lang => {
    switch (lang) {
        case '1033':
            return lang_1033;
        case '10':
            return lang_10;
        case '21':
            return lang_21;
        default:
            return lang_1033;
    }
};

export const SAMPLE_TEXT = 'SAMPLE TEXT';
export const ACTION_BTNS = [
    {
        imgSrc: View,
        code: 4,
    },
    {
        imgSrc: Edit,
        code: 7,
    },
    {
        imgSrc: Upload,
        code: 6,
    },
    {
        imgSrc: Trash,
        code: 8,
    },
];

export const ACTION_BTNS_2 = [
    {
        imgSrc: View,
        code: 4,
    },
    {
        imgSrc: Duplicate,
        code: 9,
    },
];
export const STATUS_ICONS = [
    {
        statusType: 'Draft',
        imgSrc: Draft,
    },
    {
        statusType: 'Awaiting Approval',
        imgSrc: Clock,
    },
];
