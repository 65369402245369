import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import { Helmet } from 'react-helmet';

import Loader from '../../components/Loader';
import AuthStore from '../../common/AuthStore';
import { getStaticText } from '../../utils/constants';
import { parseJwt } from '../../utils/functions';
import loginPage from './assets/loginPage2.png';
import star from './assets/star.svg';
import star2 from './assets/star2.svg';
import logo from './assets/logo2.svg';
import ohiGroup from './assets/ohiGroup.svg';
import './index.scss';

function Login({ mid, children, getAccessDetails, userData, resetToken, removeToken }) {
    const { accessToken = '' } = AuthStore;

    const [loginState, updateState] = useState({
        isAuthed: false,
        isLoading: true,
    });

    const { isAuthed, isLoading } = loginState;
    const staticText = getStaticText();
    const { SITE_TEXT } = staticText;
    const { LOGIN_BTN_TEXT, LOGIN_TEXT, LOGIN_REFRESH_TEXT } = SITE_TEXT;

    const checkIsAuth = checkAuth => {
        updateState({ isAuthed: checkAuth, isLoading: false });
    };

    useEffect(() => {
        const jwtMatches = window.location.hash.match(/jwt=([a-zA-Z0-9_.-]+)/);
        if (jwtMatches) {
            const access_token = jwtMatches[1];
            getAccessDetails({ access_token });
            window.location.hash = '';
        } else if (accessToken) {
            checkIsAuth(true);
        } else if (window.location.pathname.includes('/auth/callback')) {
            mid.isAuthed().then(loginStatus => {
                checkIsAuth(loginStatus);
            });
        } else {
            checkIsAuth(false);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const { token } = userData;
        if (token) {
            updateState({ isAuthed: true, isLoading: false });
        }
        // eslint-disable-next-line
    }, [userData]);

    useEffect(() => {
        if (resetToken) {
            updateState({ isAuthed: false, isLoading: false });
            removeToken(false);
        }
        // eslint-disable-next-line
    }, [resetToken]);

    const makeUserLogin = () => {
        mid.isAuthed().then(loginStatus => {
            updateState({ isAuthed: loginStatus, isLoading: false });
        });
    };

    const submitForm = () => {
        updateState({ ...loginState, isLoading: true });
        const access_token = mid.accessToken();
        const infoFromToken = parseJwt(access_token);
        const { exp = 0 } = infoFromToken;
        if (exp - moment().unix() > 0) {
            getAccessDetails({ access_token });
        } else {
            mid.login().finally(() => {
                makeUserLogin();
            });
        }
    };

    return isAuthed ? (
        children
    ) : (
        <>
            <div className="ohiWrapper">
                <div className="ohiLoginContent">
                    <img src={logo} alt="" />
                </div>

                <div className="loginSection">
                    <div className="loginHeading">
                        {isLoading && <Loader position="absolute" showBackground />}
                        <div className="loginContent">
                            <div className="subHead">
                                {LOGIN_TEXT} <br /> {LOGIN_REFRESH_TEXT}
                            </div>
                            <div className="loginButton" onClick={submitForm}>
                                <div className="btn">{LOGIN_BTN_TEXT}</div>
                            </div>
                        </div>
                    </div>
                    <div className="starContent">
                        <img src={star} alt="" />
                    </div>
                    <div className="loginImgContent">
                        <div>
                            <img src={loginPage} alt="" />
                        </div>
                    </div>
                    <div className="starContent2">
                        <img src={star2} alt="" />
                    </div>
                </div>
                <div className="footerLogo">
                    <img src={ohiGroup} alt="" />
                </div>
            </div>
        </>
    );
}

Login.propTypes = {
    mid: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    resetToken: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    getAccessDetails: PropTypes.func.isRequired,
    removeToken: PropTypes.func.isRequired,
};

export default Login;
