import React, { Fragment, useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Helmet } from 'react-helmet';

import Lottie from 'react-lottie';
import AuthStore from '../../../common/AuthStore';
import FilterSvg from '../assets/Filter.svg';
import EyeSvg from '../assets/Eye.svg';
import getStaticTextDiagnose from '../constants';
import { getStaticText } from '../../../utils/constants';
import {
    getMetaValues,
    getBenchmarkName,
    getPptValues,
    sortArrayOnNumber,
    replacePssKeys,
} from '../../../utils/functions';
import './index.scss';
import colors from '../../../sass/colors';
import ErrorToast from '../../../components/InformationToast/ErrorToast';
import Down from '../assets/Down.svg';
import ActiveFilters1 from '../assets/ActiveFilters1.svg';
import InfoIcon from '../assets/infoIcon.svg';
import DwnldModal from '../../../components/InformationToast/DwnldModal';
import BulletList from '../assets/BulletList.svg';
import animationData from '../assets/lottie.json';
import SelectModal from '../../../components/SelectModal';

function QuestionBoard({
    scoreData,
    scoreDataFetched,
    getScoreData,
    toggleFilterView,
    apiParams,
    apiLoadingCount,
    activeFilter,
    errorInfo,
    filters,
    getPptData,
    defaultSettings,
    appliedFiltersInfo,
    getReport,
    getRawPptData,
    reportError,
    getSinglePastReport,
    singlePastReport,
    updateIndex,
    setAddOnTabs,
    getScoreDataForHybrid,
    scoreDataHybrid,
    scoreDataHybridFetched,
    projectsFetched,
    projects,
    selectedIds,
    callReSurveyApi,
    setReport,
    localPractice,
}) {
    const {
        report_name_or_client_name: rName = '',
        report_name = '',
        template_name: template_file_name,
        lang,
        report_id = '',
        checkme_mapping = {},
        demographic = '',
        quartileColors = {},
        is_ohi_pss_module,
        is_qbyq_4_vs_3_2_required = false,
        report_type = '',
    } = defaultSettings;
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { PRACTICE_META_CHECKS, ADD_QUES_SIZE } = staticTextDiagnose;
    const staticText = getStaticText(lang);
    const {
        SINGLE_PAGE_EXPORT_PARAMS,
        SITE_TEXT,
        VIEW_EMPLOYEE_EXPERIENCE_QUESTIONS,
        QUESTION_REPLACEMENTS,
        ING_REPORT_ID,
    } = staticText;

    const {
        QUESTION_HEAD,
        PRACTICE_SUBHEAD,
        QUESTIONS_LEGEND,
        QUESTIONS_PRACTICE_LEGEND,
        FILTERS,
        NOTE,
        QUESTION_NOTE,
        DOWNLOAD,
        ADD_QUES_TEXT,
        BENCHMARK_HEAD,
        SOURCE_TEXT,
        NUMBER_TEXT,
        NUMBER_SURVEY_TEXT,
        PPT_VALUES,
        ALL_PPT_TEXT,
        HEALTH_QUESTIONS_BOARD,
        NUMBER_OF_RESPONDENTS_N,
        EMPLOYEE_EXPERIENCE_QUESTIONS,
        AGGREMENT_LEVEL_PERCENT,
        FREQUENCY_LEVEL_PERCENT,
        EXPERIENCE_LEVEL_PERCENT,
        EX_JOURNEY_QUES,
        AGREEMENT_SCALE,
        FREQUENCY_SCALE,
        QUESTIONS_EX_LEGEND,
        PERCENT_FAVORABLE,
        PERCENTILES,
        RESURVEY_TEXT,
        BENCHMARK_NA,
    } = SITE_TEXT || {};

    const updatedCheckmeMapping = replacePssKeys(
        checkme_mapping,
        is_ohi_pss_module,
        QUESTION_REPLACEMENTS,
        'questions'
    );
    const {
        qbyq = {},
        additional_questions = [],
        survey_version = '',
        add_ques_agree: agree = [],
        add_ques_freq: freq = [],
        ex_journey_ques: ex = [],
    } = scoreData;

    const { qbyq: qbyqHybrid = {} } = scoreDataHybrid;
    const { records: recordsHybrid = {} } = qbyqHybrid;
    const { records = {}, size = '', n_respondents, n_survey } = qbyq;
    const { ohi_score, ...dataToIterate } = records;
    const [expandedList, updateExpandedList] = useState([]);
    const [dwnld, setdwnld] = useState(false);
    const isOhi4 = survey_version === '4' || survey_version === '3_2';
    const filterCheck = apiParams.filters || [];
    const { $white, $black } = colors;
    const [isExpanded, setIsExpanded] = useState(false);
    const [showReSurveyModal, setReSurveyModal] = useState(false);
    const isIngClient = String(report_id) === ING_REPORT_ID;

    const updateExpanded = index => {
        let newList = [...expandedList];
        const currentIndex = expandedList.indexOf(index);
        if (currentIndex !== -1) {
            newList = [...expandedList.slice(0, currentIndex), ...expandedList.slice(currentIndex + 1)];
        } else {
            newList.push(index);
        }
        setIsExpanded(true);
        updateExpandedList(newList);
    };

    useEffect(() => {
        if (!scoreDataFetched && !apiLoadingCount) {
            getScoreData(apiParams);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched, apiParams]);

    useEffect(() => {
        if (!scoreDataHybridFetched && !apiLoadingCount && is_qbyq_4_vs_3_2_required) {
            getScoreDataForHybrid({
                ...apiParams,
                is_4_vs_3: 1,
            });
        }
        // eslint-disable-next-line
    }, [scoreDataHybridFetched, apiParams]);

    const ExpandIcon = () => {
        const defaultOptions = {
            loop: false,
            autoplay: false,
            animationData,
        };

        return (
            <div className="expand-icon">
                <Lottie options={defaultOptions} />
            </div>
        );
    };

    const ExpandIconAnimated = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData,
        };

        const onClickOptions = {
            ...defaultOptions,
            loop: false,
            autoplay: false,
        };

        const handleClick = () => {
            setIsExpanded(true);
        };

        return (
            <div className="expand-icon" onClick={handleClick}>
                <Lottie options={isExpanded ? onClickOptions : defaultOptions} />
            </div>
        );
    };

    const getDataForPpt = () => {
        const { pptAccessToken = '' } = AuthStore;
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const footNote = isIngClient ? [{ name: 'footnote', value: localPractice }] : [];
        const slides = [];
        const commonData = [
            {
                name: 'source',
                value: `${SOURCE_TEXT}: ${rName} (${NUMBER_TEXT}=${size}); ${BENCHMARK_HEAD}: ${label} (${NUMBER_TEXT}=${n_respondents}, ${NUMBER_SURVEY_TEXT}=${n_survey})`,
            },
            {
                name: 'Checkme',
                is_delete: 'true',
            },
            ...footNote,
        ];
        Object.keys(dataToIterate).forEach(innerContent => {
            const data = [...commonData];
            const { meta_scores = [], children = {}, display_name = '' } = dataToIterate[innerContent];
            const { questionsMeta } = PPT_VALUES({ ques_Meta: display_name });
            const { outcome_title, practice_title, ...otherQbyQVals } = questionsMeta;
            const isNormalString = meta_scores.some(({ qtext = '' }) => isNaN(parseInt(qtext[0], 10)));
            const mScoreInUse = isNormalString ? meta_scores : sortArrayOnNumber(meta_scores, 'qtext');
            mScoreInUse.forEach(metaParent => {
                const {
                    score: max_bound = 0,
                    nuetral_score = 0,
                    bottom2_score = 0,
                    na_score = 0,
                    meta = '',
                    qtext = '',
                } = metaParent;
                data.push(
                    {
                        name: `${meta}_Chart`,
                        chart_data: getMetaValues(metaParent),
                        meta_data: {
                            max_bound,
                            min_bound: -1 * (nuetral_score + bottom2_score),
                        },
                    },
                    {
                        name: `${meta}_text`,
                        value: qtext,
                    },
                    {
                        name: `${meta}_6`,
                        value: na_score,
                    },
                    {
                        name: 'Title_text',
                        value: display_name,
                    },
                    {
                        name: `${innerContent}_outcome_title`,
                        value: outcome_title,
                    },
                    {
                        name: `${innerContent}_practice_title`,
                        value: practice_title,
                    },
                    ...getPptValues(otherQbyQVals)
                );
            });
            let oldValue = `QbyQ_${innerContent}`;
            Object.keys(children).forEach(childVal => {
                const { meta_scores: metaChildArr = [], display_name: cDisp = '' } = children[childVal];
                const isNormalStringC = metaChildArr.some(({ qtext = '' }) => isNaN(parseInt(qtext[0], 10)));
                const metaChildArrInUse = isNormalStringC ? metaChildArr : sortArrayOnNumber(metaChildArr, 'qtext');
                data.push({
                    name: `${childVal}_text`,
                    value: cDisp,
                });
                metaChildArrInUse.forEach(innerMeta => {
                    const {
                        score: max_bound = 0,
                        nuetral_score = 0,
                        bottom2_score = 0,
                        na_score = 0,
                        meta = '',
                        qtext = '',
                    } = innerMeta;
                    if (PRACTICE_META_CHECKS[innerContent] && PRACTICE_META_CHECKS[innerContent] === meta) {
                        oldValue = `${oldValue}_${survey_version}`;
                    }
                    data.push(
                        {
                            name: `${meta}_Chart`,
                            chart_data: getMetaValues(innerMeta),
                            meta_data: {
                                max_bound,
                                min_bound: -1 * (nuetral_score + bottom2_score),
                            },
                        },
                        {
                            name: `${meta}_text`,
                            value: qtext,
                        },
                        {
                            name: `${meta}_6`,
                            value: na_score,
                        }
                    );
                });
            });
            slides.push({
                identify_shape_name: 'Checkme',
                identify_shape_value: oldValue,
                data,
            });
            // slides.push({
            //     identify_shape_name: 'Checkme',
            //     identify_shape_value: 'Additional_1',
            //     is_delete: false,
            //     data,
            // });
        });
        const isNormalStringAdd = additional_questions.some(({ qtext = '' }) => isNaN(parseInt(qtext[0], 10)));
        const addQuesInUse = isNormalStringAdd
            ? additional_questions
            : sortArrayOnNumber(additional_questions, 'qtext');
        const addQuesLength = addQuesInUse.length;
        if (addQuesLength) {
            const dummyQuesToAdd = addQuesLength % ADD_QUES_SIZE;
            for (let i = 0; i < addQuesLength; i += ADD_QUES_SIZE) {
                const { questionsMeta } = PPT_VALUES();
                const { outcome_title, practice_title, ...otherQbyQVals } = questionsMeta;
                const addQusData = [
                    ...commonData,
                    ...getPptValues(otherQbyQVals),
                    {
                        name: 'Title_text',
                        value: ADD_QUES_TEXT,
                    },
                    {
                        name: 'Rectangle 6a',
                        value: ALL_PPT_TEXT[26],
                    },
                    {
                        name: 'Rectangle 6',
                        value: ALL_PPT_TEXT[27],
                    },
                ];
                const sizeToSlice = i + ADD_QUES_SIZE;
                addQuesInUse.slice(i, i + ADD_QUES_SIZE).forEach((addQus, addQusInd) => {
                    const { top2_score: score = 0, nuetral_score = 0, bottom2_score = 0, qtext: value = '' } = addQus;
                    addQusData.push(
                        {
                            name: `qbyqObjChart_${addQusInd + 1}`,
                            chart_data: getMetaValues({ score, nuetral_score, bottom2_score }),
                            meta_data: {
                                max_bound: score,
                                min_bound: -1 * (nuetral_score + bottom2_score),
                            },
                        },
                        {
                            name: `qbyqtext_${addQusInd + 1}`,
                            value,
                        }
                    );
                });

                if (sizeToSlice >= addQuesLength && dummyQuesToAdd) {
                    for (let j = dummyQuesToAdd + 1; j <= ADD_QUES_SIZE; j += 1) {
                        addQusData.push(
                            {
                                name: `qbyqObjChart_${j}`,
                                is_delete: true,
                            },
                            {
                                name: `qbyqtext_${j}`,
                                is_delete: true,
                            }
                        );
                    }
                }

                slides.push({
                    identify_shape_name: 'Checkme',
                    identify_shape_value: 'additional',
                    is_copy: true,
                    is_delete: false,
                    data: addQusData,
                });
            }
            slides.push({
                identify_shape_name: 'Checkme',
                identify_shape_value: 'additional',
                is_delete: true,
            });
        }

        const output = {
            output_file_name: 'data.pptx',
            template_file_name,
            slides,
        };
        getPptData(output, pptAccessToken, `${report_name} qbyq Report`);
    };

    const v4PptDownload = () => {
        const addOns = {
            fill_threshold_with: demographic === '1' ? '-' : '',
            others: demographic === '3' ? 1 : 0,
        };
        const { benchmarks = [] } = filters[0];
        const label = getBenchmarkName(benchmarks);
        const valueToSend = {
            ...SINGLE_PAGE_EXPORT_PARAMS({ BENCHMARK_NAME: label }),
            ...addOns,
            report_name: rName,
            ...apiParams,
            report_id,
            single_page_meta: {
                checkme: !is_qbyq_4_vs_3_2_required
                    ? updatedCheckmeMapping.questions
                    : [...updatedCheckmeMapping.questions, 'additional_a_3_2', 'additional_b_3_2'],
                name: 'questions',
            },
        };
        setdwnld(true);
        getReport(valueToSend);
    };

    const renderAdditionalQues = (addQuestions, header, scaleHeader, listIndex, legends) => {
        const isNormalStringAdd = addQuestions.some(({ qtext = '' }) => isNaN(parseInt(qtext[0], 10)));
        const addQuesInUse = isNormalStringAdd ? addQuestions : sortArrayOnNumber(addQuestions, 'qtext');
        return (
            <Accordion classes={{ root: 'accordionRoot' }} expanded={expandedList.includes(listIndex)}>
                <AccordionSummary
                    expandIcon={<ExpandIcon />}
                    onClick={() => updateExpanded(listIndex)}
                    classes={{ content: 'accordionHead' }}
                >
                    <div className="outcomeContent">
                        {header}
                        <ul className={c({ ohi4Legends: isOhi4 }, 'questionLegends')}>
                            <li className="listTitle">{scaleHeader}</li>
                            {legends.map(legendText => (
                                <li key={legendText}>
                                    <span />
                                    {legendText}
                                </li>
                            ))}
                        </ul>
                    </div>
                </AccordionSummary>
                <AccordionDetails classes={{ root: 'clearfix' }}>
                    <section className="leftSection">
                        <div className="clearfix">
                            {addQuesInUse.map(
                                ({
                                    qtext = '',
                                    bottom2_score = 0,
                                    nuetral_score = 0,
                                    top2_score = 0,
                                    na_score = '',
                                }) => {
                                    const totalScore = bottom2_score + nuetral_score + top2_score;
                                    return (
                                        <section key={qtext} className="metaSection clearfix">
                                            <div className="metaText">{qtext}</div>
                                            {totalScore ? (
                                                <div
                                                    className={c(
                                                        {
                                                            ohi4Meta: isOhi4,
                                                        },
                                                        'metaScore'
                                                    )}
                                                >
                                                    <div>
                                                        <span style={{ width: `${bottom2_score}%` }}>
                                                            {bottom2_score}
                                                        </span>
                                                        <span style={{ width: `${nuetral_score}%` }}>
                                                            {nuetral_score}
                                                        </span>
                                                        <span style={{ width: `${top2_score}%` }}>{top2_score}</span>
                                                        <span className="naScore">{`${na_score}%`}</span>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </section>
                                    );
                                }
                            )}
                        </div>
                        <br />
                    </section>
                </AccordionDetails>
            </Accordion>
        );
    };

    const renderAddQuesSection = quesArr => {
        if (!quesArr.length) return null;
        switch (quesArr) {
            case additional_questions:
                return renderAdditionalQues(
                    additional_questions,
                    ADD_QUES_TEXT,
                    AGGREMENT_LEVEL_PERCENT,
                    100,
                    QUESTIONS_LEGEND
                );
            case agree:
                return renderAdditionalQues(
                    agree,
                    `${ADD_QUES_TEXT} - ${AGREEMENT_SCALE}`,
                    AGGREMENT_LEVEL_PERCENT,
                    101,
                    QUESTIONS_LEGEND
                );
            case freq:
                return renderAdditionalQues(
                    freq,
                    `${ADD_QUES_TEXT} - ${FREQUENCY_SCALE}`,
                    FREQUENCY_LEVEL_PERCENT,
                    102,
                    QUESTIONS_PRACTICE_LEGEND
                );
            case ex:
                return renderAdditionalQues(ex, EX_JOURNEY_QUES, EXPERIENCE_LEVEL_PERCENT, 103, QUESTIONS_EX_LEGEND);
            default:
                return null;
        }
    };

    const oldSelectedId = selectedIds.length ? selectedIds[0] : '';

    const selectProject = (ohId, reportType) => {
        setReSurveyModal(false);
        callReSurveyApi([ohId]);
        setReport(reportType);
    };

    return (
        <>
            <div className="questionBoard clearfix">
                <Helmet>
                    <title>{HEALTH_QUESTIONS_BOARD}</title>
                </Helmet>

                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">{QUESTION_HEAD}</div>
                        <div className="nRes">
                            {NUMBER_OF_RESPONDENTS_N} : <span>{size.toLocaleString('en-US')}</span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        {isOhi4 && (
                            <li
                                data-testid="setReSurveyModalBtn"
                                onClick={() => setReSurveyModal(true)}
                                className="fImage resurveyImg"
                            >
                                <img src={BulletList} alt="" />
                                <span className="upperHead">{RESURVEY_TEXT}</span>
                            </li>
                        )}
                        {!errorInfo && (
                            <li
                                data-testid="getPptBtn"
                                onClick={isOhi4 ? v4PptDownload : getDataForPpt}
                                className="resurveyImg"
                            >
                                <img src={Down} alt="" />
                                <span className="lineHead upperHead">{DOWNLOAD}</span>
                            </li>
                        )}
                        <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                            <img src={activeFilter && filterCheck.length > 0 ? ActiveFilters1 : FilterSvg} alt="" />
                            <span className="filter">{FILTERS}</span>
                        </li>
                    </ul>
                </div>

                {errorInfo && <ErrorToast message={errorInfo} />}
                {dwnld && !reportError && (
                    <DwnldModal
                        getRawPptData={getRawPptData}
                        reportId={report_id}
                        open={dwnld}
                        setOpen={setdwnld}
                        getSinglePastReport={getSinglePastReport}
                        singlePastReport={singlePastReport}
                        defaultSettings={defaultSettings}
                    />
                )}
                {Object.keys(scoreData).length ? (
                    <Fragment>
                        {appliedFiltersInfo()}
                        {Object.keys(dataToIterate).map((dataSet, index) => {
                            const {
                                display_name = '',
                                children = {},
                                meta_scores = [],
                                score: outcomeScore = '',
                                quartile = '',
                            } = dataToIterate[dataSet];
                            const { children: hybridChildren = {} } = recordsHybrid[dataSet] || {};
                            const isNormalStringD = meta_scores.some(({ qtext = '' }) => isNaN(parseInt(qtext[0], 10)));
                            const mScoreInUse = isNormalStringD ? meta_scores : sortArrayOnNumber(meta_scores, 'qtext');
                            const { background = $white, color = $black, border = colors.$grey200 } =
                                quartileColors[quartile] || {};
                            const ohi4Border = isOhi4 && quartile !== '999';
                            const hybridPractices = Object.keys(hybridChildren).filter(
                                keys => !Object.keys(children).includes(keys)
                            );
                            return (
                                <Accordion
                                    classes={{ root: 'accordionRoot' }}
                                    key={index}
                                    expanded={expandedList.includes(index)}
                                >
                                    <AccordionSummary
                                        data-testid={dataSet}
                                        expandIcon={index === 0 ? <ExpandIconAnimated /> : <ExpandIcon />}
                                        onClick={() => updateExpanded(index)}
                                        classes={{ content: 'accordionHead' }}
                                    >
                                        <div className="outcomeContent">
                                            <div className="metaTotalScore">
                                                <div
                                                    className="circle"
                                                    style={{
                                                        background: ohi4Border
                                                            ? `linear-gradient(135deg, ${border} 25%, ${background} 25%)`
                                                            : background,
                                                        color,
                                                        borderColor: ohi4Border ? 'transparent' : border,
                                                        borderWidth: quartile === '' ? 1 : 2,
                                                        borderStyle: ohi4Border ? 'none' : 'solid',
                                                    }}
                                                >
                                                    {outcomeScore}
                                                </div>
                                            </div>
                                            {display_name}
                                            <ul className={c({ ohi4Legends: isOhi4 }, 'questionLegends')}>
                                                <li className="listTitle">{AGGREMENT_LEVEL_PERCENT}</li>
                                                {QUESTIONS_LEGEND.map(legendText => (
                                                    <li key={legendText}>
                                                        <span />
                                                        {legendText}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <ul className="childContent">
                                            {Object.keys(children).map(childSet => {
                                                const { display_name: childName = '' } = children[childSet];
                                                return <li key={childName}>{childName}</li>;
                                            })}
                                        </ul>
                                    </AccordionSummary>
                                    <AccordionDetails classes={{ root: 'clearfix' }}>
                                        <section className="leftSection">
                                            <div className="clearfix">
                                                {mScoreInUse.map(
                                                    ({
                                                        qtext = '',
                                                        bottom2_score = 0,
                                                        nuetral_score = 0,
                                                        score = 0,
                                                        na_score,
                                                    }) => {
                                                        const totalScore = bottom2_score + nuetral_score + score;
                                                        return (
                                                            <section key={qtext} className="metaSection clearfix">
                                                                <div className="metaText">{qtext}</div>
                                                                {totalScore ? (
                                                                    <div
                                                                        className={c(
                                                                            {
                                                                                ohi4Meta: isOhi4,
                                                                            },
                                                                            'metaScore'
                                                                        )}
                                                                    >
                                                                        <div>
                                                                            <span
                                                                                style={{ width: `${bottom2_score}%` }}
                                                                            >
                                                                                {bottom2_score}
                                                                            </span>
                                                                            <span
                                                                                style={{ width: `${nuetral_score}%` }}
                                                                            >
                                                                                {nuetral_score}
                                                                            </span>
                                                                            <span style={{ width: `${score}%` }}>
                                                                                {score}
                                                                            </span>
                                                                            <span className="naScore">{`${na_score}%`}</span>
                                                                        </div>
                                                                    </div>
                                                                ) : null}
                                                            </section>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <h3 className="clearfix practiceHead">
                                                {display_name} {PRACTICE_SUBHEAD}
                                                <ul className={c({ ohi4Legends: isOhi4 }, 'questionLegends')}>
                                                    <li className="listTitle">Frequency Level in %</li>
                                                    {QUESTIONS_PRACTICE_LEGEND.map(legendText => (
                                                        <li key={legendText}>
                                                            <span />
                                                            {legendText}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </h3>
                                            <div className="practiceSet">
                                                {Object.keys(children).map(practiceSet => {
                                                    const {
                                                        display_name: practiceSetName = '',
                                                        meta_scores: practiceSetScore = [],
                                                        quartile: prQuartile = '',
                                                        score: practiceScore = '',
                                                    } = children[practiceSet];

                                                    const { meta_scores: hybrid_metas = [] } =
                                                        hybridChildren[practiceSet] || {};
                                                    const isNormalString = practiceSetScore.some(({ qtext = '' }) =>
                                                        isNaN(parseInt(qtext[0], 10))
                                                    );
                                                    const metasToFilter = practiceSetScore.map(({ meta = '' }) => meta);
                                                    const hybridQuesToShow = hybrid_metas.filter(
                                                        ({ meta = '' }) => !metasToFilter.includes(meta)
                                                    );
                                                    const practiceSetInUse = isNormalString
                                                        ? practiceSetScore
                                                        : sortArrayOnNumber(practiceSetScore, 'qtext');
                                                    const {
                                                        background: prBg = $white,
                                                        color: prColor = $black,
                                                        border: prBorder = colors.$grey200,
                                                    } = quartileColors[prQuartile] || {};
                                                    const ohi4PrBorder = isOhi4 && prQuartile !== '999';
                                                    return (
                                                        <div key={practiceSetName} className="clearfix">
                                                            {practiceSetInUse.map(
                                                                (
                                                                    {
                                                                        qtext: metaPracticeText = '',
                                                                        bottom2_score = 0,
                                                                        nuetral_score = 0,
                                                                        score = 0,
                                                                        na_score = '',
                                                                    },
                                                                    indexChild
                                                                ) => {
                                                                    const totalScore =
                                                                        bottom2_score + nuetral_score + score;
                                                                    return (
                                                                        <Fragment>
                                                                            {!indexChild ? (
                                                                                <div className="practiceContent">
                                                                                    <div className="metaTotalScore">
                                                                                        <div
                                                                                            className="circle"
                                                                                            style={{
                                                                                                background: ohi4PrBorder
                                                                                                    ? `linear-gradient(135deg, ${prBorder} 25%, ${prBg} 25%)`
                                                                                                    : prBg,
                                                                                                color: prColor,
                                                                                                borderColor: ohi4PrBorder
                                                                                                    ? 'transparent'
                                                                                                    : prBorder,
                                                                                                borderWidth:
                                                                                                    quartile === ''
                                                                                                        ? 1
                                                                                                        : 2,
                                                                                                borderStyle: ohi4PrBorder
                                                                                                    ? 'none'
                                                                                                    : 'solid',
                                                                                            }}
                                                                                        >
                                                                                            {practiceScore}
                                                                                        </div>
                                                                                    </div>
                                                                                    <h4>{practiceSetName}</h4>
                                                                                </div>
                                                                            ) : null}
                                                                            <section className="metaSection clearfix">
                                                                                <div className="metaText">
                                                                                    {metaPracticeText}
                                                                                </div>
                                                                                {totalScore ? (
                                                                                    <div
                                                                                        className={c(
                                                                                            {
                                                                                                ohi4Meta: isOhi4,
                                                                                            },
                                                                                            'metaScore'
                                                                                        )}
                                                                                    >
                                                                                        <div>
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${bottom2_score}%`,
                                                                                                }}
                                                                                            >
                                                                                                {bottom2_score}
                                                                                            </span>
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${nuetral_score}%`,
                                                                                                }}
                                                                                            >
                                                                                                {nuetral_score}
                                                                                            </span>
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${score}%`,
                                                                                                }}
                                                                                            >
                                                                                                {score}
                                                                                            </span>
                                                                                            <span className="naScore">{`${na_score}%`}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </section>
                                                                        </Fragment>
                                                                    );
                                                                }
                                                            )}
                                                            {hybridQuesToShow.map(
                                                                ({
                                                                    qtext: metaPracticeText = '',
                                                                    bottom2_score = 0,
                                                                    nuetral_score = 0,
                                                                    score = 0,
                                                                    na_score = '',
                                                                }) => {
                                                                    const totalScore =
                                                                        bottom2_score + nuetral_score + score;
                                                                    return (
                                                                        <Fragment>
                                                                            <section className="metaSection clearfix hybrid">
                                                                                <div className="metaText">
                                                                                    <span className="infoIcon">
                                                                                        <Tooltip title="This is an Additional Questions, and will not effect the total score of the practice and outcome.">
                                                                                            <img
                                                                                                src={InfoIcon}
                                                                                                alt=""
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </span>
                                                                                    {metaPracticeText}
                                                                                </div>
                                                                                {totalScore ? (
                                                                                    <div
                                                                                        className={c(
                                                                                            {
                                                                                                ohi4Meta: isOhi4,
                                                                                            },
                                                                                            'metaScore'
                                                                                        )}
                                                                                    >
                                                                                        <div>
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${bottom2_score}%`,
                                                                                                }}
                                                                                            >
                                                                                                {bottom2_score}
                                                                                            </span>
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${nuetral_score}%`,
                                                                                                }}
                                                                                            >
                                                                                                {nuetral_score}
                                                                                            </span>
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${score}%`,
                                                                                                }}
                                                                                            >
                                                                                                {score}
                                                                                            </span>
                                                                                            <span className="naScore">{`${na_score}%`}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </section>
                                                                        </Fragment>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                                {hybridPractices.map(practiceSet => {
                                                    const {
                                                        display_name: practiceSetName = '',
                                                        meta_scores: practiceSetScore = [],
                                                    } = hybridChildren[practiceSet];

                                                    const isNormalString = practiceSetScore.some(({ qtext = '' }) =>
                                                        isNaN(parseInt(qtext[0], 10))
                                                    );
                                                    const practiceSetInUse = isNormalString
                                                        ? practiceSetScore
                                                        : sortArrayOnNumber(practiceSetScore, 'qtext');
                                                    return (
                                                        <div key={practiceSetName} className="clearfix">
                                                            {practiceSetInUse.map(
                                                                (
                                                                    {
                                                                        qtext: metaPracticeText = '',
                                                                        bottom2_score = 0,
                                                                        nuetral_score = 0,
                                                                        score = 0,
                                                                        na_score = '',
                                                                    },
                                                                    indexChild
                                                                ) => {
                                                                    const totalScore =
                                                                        bottom2_score + nuetral_score + score;
                                                                    return (
                                                                        <Fragment>
                                                                            {!indexChild ? (
                                                                                <div className="practiceContent">
                                                                                    <h4 className="hybrid">
                                                                                        {practiceSetName}
                                                                                    </h4>
                                                                                </div>
                                                                            ) : null}
                                                                            <section className="metaSection clearfix hybrid">
                                                                                <div className="metaText">
                                                                                    <span className="infoIcon">
                                                                                        <Tooltip title="This is an Additional Questions, and will not effect the total score of the practice and outcome.">
                                                                                            <img
                                                                                                src={InfoIcon}
                                                                                                alt=""
                                                                                            />
                                                                                        </Tooltip>
                                                                                    </span>
                                                                                    {metaPracticeText}
                                                                                </div>
                                                                                {totalScore ? (
                                                                                    <div
                                                                                        className={c(
                                                                                            {
                                                                                                ohi4Meta: isOhi4,
                                                                                            },
                                                                                            'metaScore'
                                                                                        )}
                                                                                    >
                                                                                        <div>
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${bottom2_score}%`,
                                                                                                }}
                                                                                            >
                                                                                                {bottom2_score}
                                                                                            </span>
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${nuetral_score}%`,
                                                                                                }}
                                                                                            >
                                                                                                {nuetral_score}
                                                                                            </span>
                                                                                            <span
                                                                                                style={{
                                                                                                    width: `${score}%`,
                                                                                                }}
                                                                                            >
                                                                                                {score}
                                                                                            </span>
                                                                                            <span className="naScore">{`${na_score}%`}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : null}
                                                                            </section>
                                                                        </Fragment>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </section>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                        {renderAddQuesSection(additional_questions)}
                        {renderAddQuesSection(agree)}
                        {renderAddQuesSection(freq)}
                        {renderAddQuesSection(ex)}
                        {isOhi4 ? (
                            <Accordion classes={{ root: 'accordionRoot' }} expanded={false}>
                                <AccordionSummary
                                    expandIcon={null}
                                    onClick={() => {}}
                                    classes={{ content: 'accordionHead' }}
                                >
                                    <div className="outcomeContent">
                                        {EMPLOYEE_EXPERIENCE_QUESTIONS}
                                        <span
                                            className="empExpQues"
                                            onClick={() => {
                                                updateIndex(7);
                                                setAddOnTabs(1);
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            <span>
                                                <img src={EyeSvg} alt="" />
                                            </span>
                                            {VIEW_EMPLOYEE_EXPERIENCE_QUESTIONS}
                                        </span>
                                    </div>
                                </AccordionSummary>
                            </Accordion>
                        ) : null}
                        {isIngClient ? (
                            <div>
                                <div>{localPractice}</div>
                            </div>
                        ) : null}
                        <div className="note">
                            <b>{NOTE}:</b>
                            <br />
                            1. {QUESTION_NOTE}
                            <br />
                            {report_type === 'percent favorable' && <div>2. {PERCENT_FAVORABLE}</div>}
                            {report_type === 'percentile' && <div>2.{PERCENTILES}</div>}
                        </div>
                    </Fragment>
                ) : null}
                {showReSurveyModal && (
                    <SelectModal
                        defaultSettings={defaultSettings}
                        oldSelectedId={oldSelectedId}
                        projects={projects}
                        projectsFetched={projectsFetched}
                        closeModal={setReSurveyModal}
                        selectProject={selectProject}
                        practiceRankModal
                    />
                )}
            </div>

            {isOhi4 ? (
                <>
                    <ul className="benchmark clearfix fixedScoreCardSurvey">
                        <li>{BENCHMARK_HEAD} : </li>
                        {quartileColors
                            .slice()
                            .reverse()
                            .map(({ title, background, border: borderColor }) => {
                                const bottomDecileVar = title === 'Bottom decile';
                                const BenchmarkTitle = title === 'No benchmarks';
                                return (
                                    <li
                                        key={title}
                                        style={{ color: background }}
                                        className={c({
                                            hide: bottomDecileVar || BenchmarkTitle,
                                        })}
                                    >
                                        <span
                                            className="benchmarkTriangle"
                                            style={{
                                                background,
                                                borderWidth: '13px 13px 0 0',
                                                borderColor: bottomDecileVar
                                                    ? `transparent ${borderColor} transparent transparent`
                                                    : `${borderColor} transparent transparent transparent`,
                                                borderStyle: 'solid',
                                            }}
                                        />
                                        <div style={{ color: 'black' }}>{title}</div>
                                    </li>
                                );
                            })}
                        <li>
                            <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                            {BENCHMARK_NA}
                        </li>
                    </ul>
                </>
            ) : (
                <ul className="benchmark clearfix fixedScoreCardSurvey">
                    <li>{BENCHMARK_HEAD} : </li>
                    {quartileColors
                        .slice()
                        .reverse()
                        .map(({ title, background, border: borderColor }) => {
                            const bottomDecileVar = title === 'Bottom decile';
                            const BenchmarkTitle = title === 'No benchmarks';
                            return (
                                <li
                                    key={title}
                                    style={{ color: background }}
                                    className={c({
                                        hide: bottomDecileVar || BenchmarkTitle,
                                    })}
                                >
                                    <span
                                        className="benchmarkTriangle"
                                        style={{
                                            background,
                                            borderWidth: '13px 13px 0 0',
                                            border: borderColor,
                                            borderStyle: 'solid',
                                        }}
                                    />
                                    <div style={{ color: 'black' }}>{title}</div>
                                </li>
                            );
                        })}
                    <li>
                        <span style={{ background: colors.$white, border: '1.5px solid #e6e6e6' }} />
                        {BENCHMARK_NA}
                    </li>
                </ul>
            )}
        </>
    );
}

QuestionBoard.propTypes = {
    errorInfo: PropTypes.string.isRequired,
    apiParams: PropTypes.object.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    scoreData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    activeFilter: PropTypes.bool.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    getScoreData: PropTypes.func.isRequired,
    toggleFilterView: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    getPptData: PropTypes.func.isRequired,
    appliedFiltersInfo: PropTypes.func.isRequired,
    getRawPptData: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    reportError: PropTypes.string.isRequired,
    getSinglePastReport: PropTypes.func.isRequired,
    singlePastReport: PropTypes.object.isRequired,
    updateIndex: PropTypes.func.isRequired,
    setAddOnTabs: PropTypes.func.isRequired,
    getScoreDataForHybrid: PropTypes.func.isRequired,
    scoreDataHybrid: PropTypes.object.isRequired,
    scoreDataHybridFetched: PropTypes.bool.isRequired,
    localPractice: PropTypes.string.isRequired,
    projectsFetched: PropTypes.bool.isRequired,
    projects: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
    callReSurveyApi: PropTypes.func.isRequired,
    setReport: PropTypes.func.isRequired,
};

export default QuestionBoard;
