import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import colors from '../../../sass/colors';
import { getStaticText } from '../../../utils/constants';
import './index.scss';
import FocusArea from '../assets/FocusArea.svg';
import viewIcon from '../../Reports/assets/viewIcon.svg';
import { getBenchmarkName, updateFilterParams, getFiltersAppliedText, getOutputFilter } from '../../../utils/functions';
import AuthStore from '../../../common/AuthStore';

function PracticeBuBoard({
    defaultSettings,
    practiceBuData,
    buData,
    isChecked,
    tornado,
    isToggled,
    practiceDataHere,
    apiParams,
    filters,
    setFilters,
    setToggled,
}) {
    const { $white } = colors;
    const { lang, quartileColors = [], year: bmYear = '', report_type = '' } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const {
        COMPARE_TO_RECIPE,
        UNDER_EMPHASIZED,
        VIEW_MORE,
        VIEW_LESS,
        BENCHMARK_HEAD,
        // ORG_DEMOGRAPHIC,
        DEMOGRAPHIC,
        SHOW_TOP_12_BU_RANK,
        TOP_RANKING,
        MIDDLE_RANKING,
        BOTTOM_RANKING,
        RANK,
        SCORE_TXT,
        RANKING,
        GRAPH_DIFFERENCE,
        PRACTICES,
        PERCENT_FAVORABLE,
        PERCENTILES,
    } = SITE_TEXT || {};
    const listRef = useRef(null);
    const [showRanking, setShowRanking] = useState(1);
    const [isSticky, setIsSticky] = useState(false);
    const [rankingData, setRankingData] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [showCompAll, setShowCompAll] = useState(false);
    const { sizes = {} } = buData || {};

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    const handleToggle = () => {
        if (!tornado) {
            setToggled(!isToggled);
        }
    };

    useEffect(() => {
        if (practiceDataHere.length > 0 && practiceBuData.length > 0) {
            const rowsWithData = practiceBuData.map(item => {
                const matchItem = practiceBuData.find(otherItem => otherItem.slug2 === item.slug1);
                const { records = {} } = buData;
                const childrenKeys = [];
                Object.entries(records).forEach(([, value]) => {
                    if (value.children) {
                        const childrenObjectKeys = Object.values(value.children);

                        childrenKeys.push(...childrenObjectKeys);
                    }
                });
                const newItemHere = childrenKeys.find(items => items.display_name === item.text1);
                return {
                    compIndex: item.index,
                    text1: item.text1,
                    value: item.value,
                    buIndex: matchItem ? matchItem.index : 'N/A',
                    score: newItemHere ? newItemHere.score : 'N/A',
                    quartile: newItemHere ? newItemHere.quartile : 'N/A',
                };
            });
            if (isToggled) {
                const newSortedData = [...rowsWithData.sort((a, b) => a.buIndex - b.buIndex)];
                setRankingData(newSortedData);
            } else {
                setRankingData(rowsWithData);
            }
        }
        // eslint-disable-next-line
    }, [practiceDataHere, practiceBuData, isToggled]);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            setIsSticky(offset > 200);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const itemHeight = 200;
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;

        const targetIndex = Math.floor(scrollPosition / itemHeight);

        if (targetIndex >= 0 && targetIndex <= 2) {
            setShowRanking(1);
        } else if (targetIndex >= 3 && targetIndex <= 6) {
            setShowRanking(2);
        } else if (targetIndex >= 6 && targetIndex <= 7) {
            setShowRanking(3);
        }
    };

    const handleClicks = (position, targetIndex) => {
        if (listRef.current && !tornado) {
            const listItems = listRef.current.querySelectorAll('.practiceContent');

            if (position === 'top') {
                window.scrollTo({ top: 0, behavior: 'instant' });
            } else if (position === 'medium') {
                listItems[targetIndex].scrollIntoView({ behavior: 'instant', block: 'start' });
            } else if (position === 'bottom') {
                const listItem = listRef.current.children[practiceBuData.length - 12];
                listItem.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'nearest' });
            }

            if (targetIndex === 0) {
                setShowRanking(1);
            } else if (targetIndex === 11) {
                setShowRanking(2);
            } else if (targetIndex > 11) {
                setShowRanking(3);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const toggleCompShowAll = () => {
        setShowCompAll(!showCompAll);
    };

    const practiceBuName = AuthStore.practiceBU || 'Bu';
    const practiceReportName = AuthStore.practiceReportBU || 'Organization';
    const middleNumber = isChecked ? 10 : 11;

    const handleAllFilterData = (
        parKey,
        childKey,
        demographic,
        filtersHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks = [] } = filtersHere[0];
        const allList = { demographics: newDemographic, benchmarks };
        const newList = [...filters.slice(0, 0), allList, ...filters.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 0);
        }
    };

    const handleAllCompFilter = (
        parKey,
        childKey,
        compDemographic,
        buFilterHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(compDemographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks: compBenchmark = [] } = buFilterHere[1];
        const allList = { demographics: newDemographic, compBenchmark };
        const newList = [...filters.slice(0, 1), allList];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 1);
        }
    };

    const maxItemsToShow = 6;

    const appliedBuFiltersContent = () => {
        const { demographics: budemographics = [], benchmarks: compBenchmarks = [] } = filters[1] || {};
        const compDemographic = budemographics || [];
        const { benchmarks: bm = [], demographics = [] } = filters[0] || {};
        const { filters: filterApplied = [] } = apiParams;
        const label = getBenchmarkName(bm);
        const { filters: compFilter = [] } = updateFilterParams(compDemographic, compBenchmarks) || {};

        const arrItem = getFiltersAppliedText(filterApplied, demographics);
        const newItem = getOutputFilter(filterApplied, demographics);
        const arrItemComp = getFiltersAppliedText(compFilter, compDemographic);
        const newItemComp = getOutputFilter(compFilter, compDemographic);
        const currentSize = sizes.nSize || [];
        const buRespondents = sizes.n_respondents || [];
        const buSurvey = sizes.n_survey || [];

        return (
            <div className="filterAppliedContent" style={{ marginBottom: '0' }}>
                {arrItem.length > 0 && newItem !== null && (
                    <div className="filterDemo">
                        {newItem !== null && (
                            <div className="heading">
                                {practiceReportName} {DEMOGRAPHIC} :{' '}
                            </div>
                        )}
                        <div className="nsizeContent">
                            <div>n : </div>
                            <div>{currentSize[0]}</div>
                        </div>
                        {newItem !== null &&
                            newItem
                                .slice(0, showAll ? newItem.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllFilterData(parentCode, childCode, demographics, filters);
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItem.length >= maxItemsToShow || showAll) && (
                            <div onClick={toggleShowAll}>
                                {showAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {arrItemComp.length > 0 && newItemComp !== null && (
                    <div className="filterDemo">
                        {newItemComp !== null && (
                            <div className="heading">
                                {practiceBuName} {DEMOGRAPHIC} :{' '}
                            </div>
                        )}
                        <div className="nsizeContent">
                            <div>n : </div>
                            <div>{currentSize[1]}</div>
                        </div>
                        {newItemComp !== null &&
                            newItemComp
                                .slice(0, showCompAll ? newItemComp.length : maxItemsToShow)
                                .map(({ parentK, childKey, parentCode, childCode }) => {
                                    return (
                                        <div className="filterContent">
                                            <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                <div
                                                    key={parentCode}
                                                    className="filterHighlight filterKey"
                                                >{`${childKey}`}</div>
                                            </LightTooltip>
                                            <img
                                                src={FocusArea}
                                                alt=""
                                                className="filterImg"
                                                onClick={() => {
                                                    handleAllCompFilter(
                                                        parentCode,
                                                        childCode,
                                                        compDemographic,
                                                        filters
                                                    );
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                        {(newItemComp.length >= maxItemsToShow || showCompAll) && (
                            <div onClick={toggleCompShowAll}>
                                {showCompAll ? (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_LESS}</div>
                                    </div>
                                ) : (
                                    <div className="viewIconContent">
                                        <img src={viewIcon} alt="" className="viewImg" />
                                        <div className="viewText">{VIEW_MORE}</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                <div className="filterDemo">
                    <div className="heading">{BENCHMARK_HEAD}: </div>
                    <div className="nsizeContent">
                        <div>Year :</div>
                        <div>{bmYear}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>n :</div>
                        <div>{buRespondents}</div>
                    </div>
                    <div className="nsizeContent">
                        <div>No of surveys :</div>
                        <div>{buSurvey}</div>
                    </div>
                    <div className="filterContent">
                        <div className="filterHighlight filterKey filterLab">{label}</div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <div className="newContentForBu">{appliedBuFiltersContent()}</div>
            <ul className="toggledContent">
                <li className="toggleBtn separator">
                    <div className={c('conBtn', { overallView: isToggled, disabled: tornado })} onClick={handleToggle}>
                        <div data-testid="hideGapBtn" className={c('contentBtn', { isActive: !isToggled })} />
                        <div data-testid="showGapBtn" className={c('contentBtn', { isActive: isToggled })} />
                    </div>
                    <div className="tagName">{SHOW_TOP_12_BU_RANK}</div>
                </li>
            </ul>
            <div className="graphContent buGraphContent">
                {rankingData.length > 0 && practiceBuData.length > 0 && (
                    <div className="groupSection">
                        <div
                            className={c('sideScrolling', {
                                stickyBtn: isSticky,
                                scoreScrolling: isChecked,
                                scoreStickyTab: isSticky && isChecked,
                            })}
                        >
                            <div
                                className={c('rankingScroll', { active: showRanking === 1 && !tornado })}
                                onClick={() => handleClicks('top', 0)}
                            >
                                <div className="ranking">{TOP_RANKING}</div>
                            </div>
                            <div
                                className={c('rankingScroll', { active: showRanking === 2 && !tornado })}
                                onClick={() => handleClicks('medium', middleNumber)}
                            >
                                <div className="ranking">{MIDDLE_RANKING}</div>
                            </div>
                            <div
                                className={c('rankingScroll', { active: showRanking === 3 && !tornado })}
                                onClick={() => handleClicks('bottom', practiceBuData.length - 12)}
                            >
                                <div className="ranking">{BOTTOM_RANKING}</div>
                            </div>
                        </div>

                        <div className="rankingBuSection">
                            <div className="headerNav">
                                <div
                                    style={{ width: '35%', padding: '1.5vh' }}
                                    className="headingContent headingBuComparison"
                                >
                                    {PRACTICES}
                                </div>
                                <div style={{ width: '15%', textAlign: 'center' }} className="headingContent">
                                    <div style={{ padding: '1.5vh' }}>{practiceReportName}</div>
                                    {isChecked && (
                                        <div className="showScores">
                                            <div className="checkHeader">{RANK}</div>
                                            <div className="checkHeader headerBorder">{SCORE_TXT}</div>
                                        </div>
                                    )}
                                </div>
                                <div style={{ width: '15%', textAlign: 'center' }} className="headingContent">
                                    <div style={{ padding: '1.5vh' }}>{practiceBuName}</div>
                                    {isChecked && (
                                        <div className="showScores">
                                            <div className="checkHeader">{RANK}</div>
                                            <div className="checkHeader headerBorder">{SCORE_TXT}</div>
                                        </div>
                                    )}
                                </div>

                                <div
                                    style={{ width: '35%', textAlign: 'center', padding: '1.5vh' }}
                                    className="headingContent"
                                >
                                    {practiceReportName} vs {practiceBuName}
                                </div>
                            </div>
                            <div className="mainContent" ref={listRef}>
                                {rankingData.map(item => {
                                    const { color, background = $white, border = colors.$grey200 } =
                                        quartileColors[item.quartile[0]] || {};
                                    const {
                                        color: buColor,
                                        background: buBackground = $white,
                                        border: buBorder = colors.$grey200,
                                    } = quartileColors[item.quartile[1]] || {};
                                    const styleBlack =
                                        item.quartile[0] === ('' || 999) ? { border: '2px solid #000000' } : {};
                                    const listsWithBottomBorder = 12;
                                    const newItem = practiceBuData.length - listsWithBottomBorder;
                                    return (
                                        <div
                                            className={c('practiceContent', {
                                                newbottomBorder: item.compIndex <= 12 && !isChecked && !tornado,
                                                newbottom: item.compIndex === newItem && !isChecked && !tornado,
                                            })}
                                        >
                                            <div
                                                style={{ width: '35%' }}
                                                className={c('practiceText', {
                                                    topRank: item.compIndex <= 12,
                                                    topLastRank: item.compIndex >= newItem + 1,
                                                })}
                                            >
                                                {item.text1}
                                            </div>

                                            <div
                                                style={{ width: '15%', textAlign: 'center' }}
                                                className="practiceIndex"
                                            >
                                                <div className="orgRanks">
                                                    <span
                                                        className={c('compRound', {
                                                            comp: item.compIndex <= 12,
                                                            topRank: item.compIndex <= 12,
                                                            topLastRank: item.compIndex >= newItem + 1,
                                                        })}
                                                    >
                                                        {item.compIndex}
                                                    </span>
                                                </div>
                                                {isChecked && (
                                                    <div className="orgRanks orgBorder">
                                                        <span
                                                            style={{
                                                                background,
                                                                color,
                                                                ...styleBlack,
                                                            }}
                                                            className="scoreContent"
                                                        >
                                                            <div
                                                                style={{
                                                                    borderColor: `${border} transparent transparent transparent`,
                                                                }}
                                                                className="scoreElement"
                                                            />
                                                            <span>{item.score[0]}</span>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                style={{ width: '15%', textAlign: 'center' }}
                                                className="practiceIndex"
                                            >
                                                <div className="orgRanks">
                                                    <span
                                                        className={c('compRound', {
                                                            comp: item.buIndex <= 12,
                                                            topRank: item.buIndex <= 12,
                                                            topLastRank: item.buIndex >= newItem + 1,
                                                        })}
                                                    >
                                                        {item.buIndex}
                                                    </span>
                                                </div>
                                                {isChecked && (
                                                    <div className="orgRanks orgBorder">
                                                        <span
                                                            style={{
                                                                background: buBackground,
                                                                color: buColor,
                                                                ...styleBlack,
                                                            }}
                                                            className="scoreContent"
                                                        >
                                                            <div
                                                                style={{
                                                                    borderColor: `${buBorder} transparent transparent transparent`,
                                                                }}
                                                                className="scoreElement"
                                                            />
                                                            <span>{item.score[1]}</span>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className="clearfix newRank"
                                                style={{ width: '35%', display: 'flex', alignItems: 'center' }}
                                            >
                                                <div
                                                    className={c('rank rankContent', { negative: item.value < 0 })}
                                                    style={{ top: 0 }}
                                                >
                                                    <div
                                                        className={c('textBorder', { newEmphasised: item.value < -10 })}
                                                        style={{
                                                            width: `${2 * Math.abs(item.value)}%`,
                                                        }}
                                                    >
                                                        <div className="textRank">{item.value}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <div className="graphHighlight">
                                    <div className="highLight" />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={c('legendSpace', { activeScroll: isChecked })}>
                    <div className="legends clearfix">
                        <ul>
                            <li style={{ fontWeight: '500', margin: '0 1vw 0 0', textTransform: 'none' }}>
                                {RANKING} :
                            </li>
                            <li>
                                <span className=" bullet rankingHighlight" />
                                {TOP_RANKING}
                            </li>
                            <li>
                                <span className=" bullet rankingHighlight" />
                                {BOTTOM_RANKING}
                            </li>
                        </ul>
                    </div>

                    <div>
                        <ul className="legends clearfix" style={{ marginTop: '0' }}>
                            <li style={{ fontWeight: '500', margin: '0 1vw 0 0', textTransform: 'none' }}>
                                {GRAPH_DIFFERENCE} :
                            </li>
                            <li>
                                <span className="bullet graphText" />
                                {UNDER_EMPHASIZED}
                            </li>
                            <li>
                                <span className="bullet graphText" />
                                {COMPARE_TO_RECIPE}
                            </li>
                        </ul>
                    </div>

                    {isChecked ? (
                        <div>
                            <ul className="benchmark clearfix fixedScoreCard" style={{ marginTop: '0' }}>
                                <li>{BENCHMARK_HEAD} : </li>
                                {quartileColors
                                    .slice()
                                    .reverse()
                                    .map(({ title: label = '', background, border: borderColor }) => {
                                        const bottomDecileVar = label === 'Bottom decile';
                                        const BenchmarkTitle = label === 'No benchmarks';
                                        return (
                                            <li
                                                key={label}
                                                style={{ color: background }}
                                                className={c({
                                                    hide: bottomDecileVar || BenchmarkTitle,
                                                })}
                                            >
                                                <span
                                                    className="benchmarkTriangle"
                                                    style={{
                                                        background,
                                                        borderWidth: '13px 13px 0 0',
                                                        borderColor: bottomDecileVar
                                                            ? `transparent ${borderColor} transparent transparent`
                                                            : `${borderColor} transparent transparent transparent`,
                                                        borderStyle: 'solid',
                                                    }}
                                                />
                                                <div style={{ color: 'black', fontWeight: '400' }}>{label}</div>
                                            </li>
                                        );
                                    })}
                            </ul>
                            {report_type === 'percent favorable' && <div>2. {PERCENT_FAVORABLE}</div>}
                            {report_type === 'percentile' && <div>2. {PERCENTILES}</div>}
                        </div>
                    ) : null}
                </div>
            </div>
        </Fragment>
    );
}

PracticeBuBoard.propTypes = {
    practiceBuData: PropTypes.array.isRequired,
    buData: PropTypes.object.isRequired,
    isChecked: PropTypes.bool.isRequired,
    tornado: PropTypes.bool.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    isToggled: PropTypes.bool.isRequired,
    practiceDataHere: PropTypes.array.isRequired,
    apiParams: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    setFilters: PropTypes.func.isRequired,
    setToggled: PropTypes.func.isRequired,
};

export default PracticeBuBoard;
