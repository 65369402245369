import React, { useState, useEffect } from 'react';
import { Dialog, IconButton, List, ListItem } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import c from 'classnames';
import { getStaticText } from '../../utils/constants';
import LeftArrow from '../../assets/left.svg';
import RightArrow from '../../assets/right.svg';

import './index.scss';

function ImageModal({ imageArr, closeModal, defaultSettings }) {
    const { lang } = defaultSettings;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { NEXT, PREVIOUS, OUT_OF } = SITE_TEXT || {};
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1);

    const [demoNo, setDemoNo] = useState(null);
    const [demoName, setDemoName] = useState(0);

    useEffect(() => {
        if (imageArr.length > 0) {
            const newDemo = imageArr.map(({ modalNo }) => modalNo)[0];
            setDemoNo(newDemo);
        }
        // eslint-disable-next-line
    }, []);

    const handleOhiVersion = index => {
        setDemoName(index);
    };

    const newOutcomeImg = () => {
        if (demoNo === 1) {
            return <div>Bubblecharts</div>;
        }
        if (demoNo === 2) {
            return <div>Demographic Deep Dive</div>;
        }
        if (demoNo === 4) {
            return <div>Outcome Inclusion Enterprise</div>;
        }
        if (demoNo === 5) {
            return <div>Practice Inclusion Enterprise</div>;
        }
        if (demoNo === 6) {
            return <div>Demographic Groups Summary</div>;
        }
        if (demoNo === 7) {
            return <div>Score Heatmaps</div>;
        }
        return <div>Sample Slides</div>;
    };

    return (
        <Dialog
            open
            disableBackdropClick
            maxWidth="md"
            fullWidth
            classes={{
                root: 'imageDialogRoot',
            }}
        >
            <section className="headContent">
                <IconButton
                    aria-label="close"
                    classes={{
                        root: 'closeButton',
                    }}
                    onClick={() => closeModal(false)}
                >
                    <CloseIcon />
                </IconButton>
            </section>

            <div className="dialogHeading">
                <div>Sample Slide</div>
            </div>
            <div className="outcomeImages">
                {imageArr.map(({ modalNo }, index) => {
                    return (
                        <List
                            onClick={() => handleOhiVersion(index)}
                            value={index}
                            className="outcomeList"
                            key={modalNo}
                        >
                            <ListItem
                                className={c('outComeListItem', {
                                    active: demoName === index,
                                })}
                            >
                                {demoNo !== 3 && newOutcomeImg(index)}
                            </ListItem>
                        </List>
                    );
                })}
            </div>
            <section className="dataContent">
                {imageArr.slice(min, max).map(({ imgSrc = '' }) => (
                    <img key={imgSrc} src={imgSrc} alt="" className="screenImg" />
                ))}
            </section>
            {imageArr.length > 1 ? (
                <section className="footerNav">
                    <div>
                        {min === 0 ? (
                            <div />
                        ) : (
                            <div>
                                <div
                                    data-testid="leftArrow"
                                    onClick={() => {
                                        setMin(min - 1);
                                        setMax(max - 1);
                                    }}
                                >
                                    <img src={LeftArrow} alt="" />
                                </div>
                                <div className="previous"> {PREVIOUS} </div>
                            </div>
                        )}

                        <div>
                            {min + 1}
                            {OUT_OF}
                            {imageArr.length}
                        </div>
                        {imageArr.length !== max ? (
                            <div>
                                <div className="next"> {NEXT} </div>
                                <div
                                    data-testid="rightArrow"
                                    onClick={() => {
                                        setMin(min + 1);
                                        setMax(max + 1);
                                    }}
                                >
                                    <img src={RightArrow} alt="" />
                                </div>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>
                </section>
            ) : null}
        </Dialog>
    );
}

ImageModal.propTypes = {
    imageArr: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired,
};

export default ImageModal;
