import React, { useState, useEffect, useCallback } from 'react';
import c from 'classnames';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { ExpandMore } from '@material-ui/icons';
import { Button, MenuItem, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { cloneDeep } from 'lodash';
import Toolbox from './Toolbox';
import Scoreboard from './Scoreboard';
import Profile from './Profile';
import QuestionBoard from './QuestionBoard';
import BenchmarkBoard from './BenchmarkBoard';
import DemographicBoard from './DemographicBoard';
import PracticeBoard from './PracticeBoard';
import RecipeBoard from './RecipeBoard';
import AddOns from './AddOns';
import Layout from '../../components/Layout';
import FilterBar from '../../components/FilterBar';
import AuthStore from '../../common/AuthStore';
import getStaticTextDiagnose from './constants';
import Loader from '../../components/Loader';
import { getBenchmarkName, getFiltersAppliedText, updateFilterParams, getOutputFilter } from '../../utils/functions';
import { getStaticText } from '../../utils/constants';
import Cohesion from './Cohesion';
import './index.scss';
import IndExperienceBoard from './IndExperienceBoard';
import FocusArea from './assets/FocusArea.svg';
import viewIcon from '../Reports/assets/viewIcon.svg';
import PenWhite from '../../assets/Pen-White.svg';
import SmallDown from '../../assets/smallDown.svg';
import Feedback from './Feedback';
// import { ChatBot } from '@ohi/genai-fe';

function Diagnose({
    apiParams,
    apiLoadingCount,
    scoreData,
    practiceData,
    filters,
    activeResetFilter,
    getDemographics,
    getBenchmarks,
    scoreDataFetched,
    practiceDataFetched,
    updateFilters,
    enableCreateTheme,
    setTheme,
    themeSelected,
    defaultSettings,
    demographicCurrentTab,
    heatMapData,
    heatMapPractice,
    heatMapDataFetched,
    cohesionDataFetched,
    heatMapPracticeFetched,
    getCohesionData,
    cohesionScore,
    reportApiCount,
    showProgressBar,
    getLocalPractice,
    localPracticeFetched,
    targetBenchmarks,
}) {
    const {
        benchmark = [],
        cohesion_default_Demographic = '',
        survey_type,
        pss_module,
        lang,
        engagement,
        comment_access,
        // ohi4_module,
        is_employee_experience = '',
        survey_version = '',
        ohid: currOhid = '',
        is_values_module = false,
        benchmark_year: bmYear = '',
        report_type = '',
        text_analytics_access = false,
        role: userRole = '',
    } = defaultSettings;
    const [activeIndex, updateIndex] = useState(0);
    const [filterOpen, toggleFilterView] = useState(false);
    const [scoreSurvey, updateScoreSurvey] = useState([0, 0, 0]);
    const [practiceSurvey, updatePracticeSurvey] = useState([0, 0, 0]);
    const [demoSurvey, updateDemoSurvey] = useState([0, 0, 0]);
    const [toolOpen, toggleTool] = useState(false);
    const [createTheme, toggleTheme] = useState(false);
    const [draggedItems, updateDragged] = useState([]);
    const [addOnOptions, showAddOnOptions] = useState(false);
    const [addOnView, setAddOnView] = useState(0);
    const [isEEpage, setEEpage] = useState(false);
    const [feedbackOpen, toggleFeedbackView] = useState(false);
    const staticText = getStaticText(lang);
    const staticTextDiagnose = getStaticTextDiagnose(lang);
    const { NAV_LINKS, PULSE_SURVEY, OUTCOME_VAR } = staticTextDiagnose;
    const {
        SITE_TEXT,
        USER_ROLES,
        NON_PROFIT_SURVEY,
        CFG_REPORT_ID,
        ING_REPORT_ID,
        TALX_REPORT_ID,
        ING_CURR_OHID,
    } = staticText;
    const {
        TOOLBOX,
        HEALTH_NAV_LINKS,
        ADD_ON_VIEWS_ING,
        ADD_ON,
        ADD_ON_VIEWS_TALANX,
        ADD_ON_NP,
        FILTERS_APPLIED,
        BENCHMARKS_APPLIED,
        NONE,
        VIEW_MORE,
        VIEW_LESS,
        DEMOGRAPHIC,
        BENCHMARK,
        FEEDBACK,
    } = SITE_TEXT || {};
    const selectedSurvey = activeIndex === 2 ? demoSurvey : scoreSurvey;
    const surveyToUse = activeIndex === 5 ? practiceSurvey : selectedSurvey;
    const pulseSurvey = survey_type === PULSE_SURVEY;
    const { mIdToken = '', report_id, accessToken = '', userName = '' } = AuthStore;
    const isClient = userRole === USER_ROLES.CLIENT;
    const isAdmin = userRole === USER_ROLES.SUPER_ADMIN || userRole === USER_ROLES.ADMIN;
    const isNonProfit = engagement === NON_PROFIT_SURVEY;
    const isIngClient = report_id === ING_REPORT_ID;
    const isTalnxClient = report_id === TALX_REPORT_ID;
    const hideAddOns = !comment_access && isNonProfit;
    const isUserNotAdmin =
        userRole && (userRole === USER_ROLES.CLIENT || userRole === USER_ROLES.CST || userRole === USER_ROLES.USER);
    const isCfgClient = !!(isUserNotAdmin && report_id === CFG_REPORT_ID);
    const showTabLatam = !!(userRole === USER_ROLES.CLIENT && currOhid === 'OHI13648');
    const showEmpExperience = is_employee_experience && (survey_version === '3_2' || survey_version === '4');
    const isOhi4 = survey_version === '4';
    const checkpercentFavourable = report_type === 'percentile';

    const isNonProfitSurvey =
        engagement === NON_PROFIT_SURVEY && survey_version === '4' && userRole === USER_ROLES.CLIENT;

    const setFilters = (newFilters, filtersApplied) => {
        toggleFilterView(false);
        const { demographics = [], benchmarks = [] } = newFilters[0];
        const updatedParams = updateFilterParams(demographics, benchmarks);
        updateFilters({ filters: newFilters, filtersApplied, apiParams: { ...apiParams, ...updatedParams } });
    };

    const addOnOpToUse = () => {
        if (isIngClient) return ADD_ON_VIEWS_ING;
        if (isTalnxClient) return ADD_ON_VIEWS_TALANX;
        if (comment_access && isNonProfit) return ADD_ON_NP;
        return ADD_ON;
    };

    const showAdditionalMenu = () => {
        if ((!showTabLatam && (is_values_module || isIngClient)) || (isTalnxClient && !isClient)) {
            return true;
        }
        if (comment_access && isNonProfit) {
            return true;
        }
        return false;
    };

    const showAdMenu = showAdditionalMenu();
    const token = accessToken.split(' ')[1];

    const [showAll, setShowAll] = useState(false);

    const LightTooltip = withStyles(theme => ({
        arrow: {
            color: theme.palette.common.black,
        },
        tooltip: {
            backgroundColor: '#333333',
            fontSize: 14,
            fontStyle: 'italic',
            fontWeight: 400,
        },
    }))(Tooltip);

    useEffect(() => {
        const { ohid, year } = apiParams;
        const { benchmarks = [], demographics = [] } = filters[0] || {};
        if (!benchmarks.length) {
            getBenchmarks({ ohid, year, lang }, 0, benchmark);
        }
        if (!demographics.length) {
            getDemographics({ ohid, lang: parseInt(lang, 10) }, 0);
        }
        if (!pulseSurvey && !isClient && !cohesionDataFetched && cohesion_default_Demographic !== '') {
            const payloadData = {
                ohid,
                lang: parseInt(lang, 10),
                type: 'practice',
                demographic: cohesion_default_Demographic,
                threshold: 50,
                columns: 500,
                page: 1,
                percentile: 0,
                filters: [],
            };
            getCohesionData(payloadData);
        }
        if (isIngClient) {
            setAddOnView(1);
        }
        // if (ohi4_module && !isIngClient) {
        //     setAddOnView(3);
        // }
        if (isTalnxClient && isClient) {
            const { codeN } = ADD_ON_VIEWS_TALANX[0];
            updateIndex(8);
            setAddOnView(codeN);
        }

        if (showTabLatam) {
            updateIndex(7);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!localPracticeFetched && currOhid === ING_CURR_OHID) {
            const { demographic = '' } = defaultSettings || {};
            const addOns = {
                fill_threshold_with: demographic === '1' ? '-' : '',
                others: demographic === '3' ? 1 : 0,
            };
            getLocalPractice({
                ...apiParams,
                ...addOns,
                type: 'practice',
                demographic: 'demo_CountryWorkLocation',
            });
        }
        // eslint-disable-next-line
    }, [localPracticeFetched]);

    useEffect(() => {
        if (showEmpExperience && activeIndex === 7) {
            setEEpage(true);
        } else {
            setEEpage(false);
        }
    }, [activeIndex, showEmpExperience]);

    useEffect(() => {
        const { qbyq = {} } = scoreData;
        const { nSize = 0, n_respondents: prN = 0, n_survey: prS = 0 } = practiceData;
        const { size = 0, n_respondents = 0, n_survey = 0 } = qbyq;
        const heatMapType = demographicCurrentTab === OUTCOME_VAR ? heatMapData : heatMapPractice;
        const { items = [], n_respondents: dRespondent = 0, n_survey: dSurvey = 0 } = heatMapType || {};
        const { items: subItems = [] } = items[0] || [];
        const { score: dSize = 0 } = subItems[0] || {};
        if (scoreDataFetched) {
            updateScoreSurvey([size, n_respondents, n_survey]);
        }
        if (practiceDataFetched) {
            updatePracticeSurvey([nSize, prN, prS]);
        }
        if (heatMapDataFetched || heatMapPracticeFetched) {
            updateDemoSurvey([dSize, dRespondent, dSurvey]);
        }
        // eslint-disable-next-line
    }, [scoreDataFetched, scoreData, practiceData, practiceDataFetched, heatMapDataFetched, heatMapPracticeFetched]);

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const handleAllFilterData = (
        parKey,
        childKey,
        demographic,
        filtersHere,
        updateRedux = true,
        activeFilter = true
    ) => {
        const newDemographic = cloneDeep(demographic);
        const newParDemoIndex = newDemographic.findIndex(({ code }) => code === parKey);
        const selectedItemIndex = newDemographic[newParDemoIndex].options.findIndex(({ code }) => code === childKey);
        newDemographic[newParDemoIndex].options[selectedItemIndex].isSelected = false;
        const { benchmarks = [] } = filtersHere[0];
        const allList = { demographics: newDemographic, benchmarks };
        const newList = [...filters.slice(0, 0), allList, ...filters.slice(0 + 1)];
        if (updateRedux === true) {
            setFilters(newList, true, activeFilter, 0);
        }
    };

    const maxItemsToShow = 6;

    const appliedFiltersInfo = useCallback(
        (nSize = '', years = [], isTargetSetting) => {
            const { qbyq = {} } = scoreData;
            const { size = 0 } = qbyq;
            const { benchmarks: bm = [], demographics = [] } = filters[0] || {};
            const benchmarks = isTargetSetting ? targetBenchmarks : bm;
            const { filters: filterApplied = [] } = apiParams;
            const label = getBenchmarkName(benchmarks);
            if (filters[1] && years) {
                const { benchmarks: compBenchmarks = [], demographics: compDemographic = [] } = filters[1];
                const { filters: compFilter = [] } = updateFilterParams(compDemographic, compBenchmarks);
                return (
                    <div className="filterInfoSection newVersion">
                        <div>
                            <b>{FILTERS_APPLIED + ` (${years[0]}) : `}</b>
                            {getFiltersAppliedText(filterApplied, demographics)
                                ? getFiltersAppliedText(filterApplied, demographics)
                                : NONE}{' '}
                            | n = {size.toLocaleString('en-US')}
                        </div>
                        <div>
                            <b>{FILTERS_APPLIED + ` (${years[1]}) : `}</b>
                            {getFiltersAppliedText(compFilter, demographics)
                                ? getFiltersAppliedText(compFilter, compDemographic)
                                : NONE}{' '}
                            | n = {nSize[1].toLocaleString('en-US')}
                        </div>
                        <div>
                            <b>{BENCHMARKS_APPLIED + ` (${years[0]}) : `}</b> {label}
                        </div>
                        <div>
                            <b>{BENCHMARKS_APPLIED + ` (${years[1]}) : `}</b> {getBenchmarkName(compBenchmarks)}
                        </div>
                    </div>
                );
            }

            const arrItem = getFiltersAppliedText(filterApplied, demographics);
            const newItem = getOutputFilter(filterApplied, demographics);
            return (
                <div className="filterAppliedContent">
                    {arrItem.length > 0 && newItem !== null && (
                        <div className="filterDemo">
                            {newItem !== null && <div className="heading">{DEMOGRAPHIC + ':'}</div>}
                            {newItem !== null &&
                                newItem
                                    .slice(0, showAll ? newItem.length : maxItemsToShow)
                                    .map(({ parentK, childKey, parentCode, childCode }) => {
                                        return (
                                            <div className="filterContent">
                                                <LightTooltip title={`${parentK} - ${childKey}`} arrow>
                                                    <div
                                                        key={parentCode}
                                                        className="filterHighlight filterKey"
                                                    >{`${childKey}`}</div>
                                                </LightTooltip>
                                                <img
                                                    src={FocusArea}
                                                    alt=""
                                                    className="filterImg"
                                                    onClick={() => {
                                                        handleAllFilterData(
                                                            parentCode,
                                                            childCode,
                                                            demographics,
                                                            filters
                                                        );
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                            {(newItem.length >= maxItemsToShow || showAll) && (
                                <div onClick={toggleShowAll}>
                                    {showAll ? (
                                        <div className="viewIconContent">
                                            <img src={viewIcon} alt="" className="viewImg" />
                                            <div className="viewText">{VIEW_LESS}</div>
                                        </div>
                                    ) : (
                                        <div className="viewIconContent">
                                            <img src={viewIcon} alt="" className="viewImg" />
                                            <div className="viewText">{VIEW_MORE}</div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}

                    <div className="filterDemo">
                        <div className="heading">{BENCHMARK + ':'}</div>
                        <div className="nsizeContent">
                            <div>Year : </div>
                            <div>{bmYear}</div>
                        </div>
                        <div className="nsizeContent">
                            <div>n : </div>
                            <div>{surveyToUse[1].toLocaleString('en-US')}</div>
                        </div>
                        <div className="nsizeContent">
                            <div>No of surveys :</div>
                            <div>{surveyToUse[2].toLocaleString('en-US')}</div>
                        </div>
                        <div className="filterContent">
                            <div className="filterHighlight filterKey filterLab">{label}</div>
                        </div>
                    </div>
                </div>
            );
        },
        // eslint-disable-next-line
        [filters, showAll, surveyToUse]
    );

    const getPage = () => {
        let page;
        switch (activeIndex) {
            case 0:
            default:
                page = (
                    <Scoreboard
                        isDragDisabled={!createTheme || draggedItems.length > 5}
                        toggleFilterView={toggleFilterView}
                        changeCurrentPage={updateIndex}
                        cohesionScore={cohesionScore}
                        pulseSurvey={pulseSurvey}
                        appliedFiltersInfo={appliedFiltersInfo}
                    />
                );
                break;
            case 1:
                page = <Profile toggleFilterView={toggleFilterView} appliedFiltersInfo={appliedFiltersInfo} />;
                break;
            case 2:
                page = (
                    <DemographicBoard
                        isCfgClient={isCfgClient}
                        isIngClient={isIngClient}
                        toolOpen={toolOpen}
                        toggleFilterView={toggleFilterView}
                        setFilters={setFilters}
                        cohesionScore={cohesionScore}
                    />
                );
                break;
            case 3:
                page = <BenchmarkBoard toggleFilterView={toggleFilterView} appliedFiltersInfo={appliedFiltersInfo} />;
                break;
            case 4:
                page = (
                    <QuestionBoard
                        toggleFilterView={toggleFilterView}
                        appliedFiltersInfo={appliedFiltersInfo}
                        updateIndex={updateIndex}
                    />
                );
                break;
            case 5:
                page = <PracticeBoard toggleFilterView={toggleFilterView} appliedFiltersInfo={appliedFiltersInfo} />;
                break;
            case 6:
                page = <Cohesion toggleFilterView={toggleFilterView} appliedFiltersInfo={appliedFiltersInfo} />;
                break;
            case 7:
                page = (
                    <IndExperienceBoard toggleFilterView={toggleFilterView} appliedFiltersInfo={appliedFiltersInfo} />
                );
                break;
            case 8:
                page = <AddOns toggleFilterView={toggleFilterView} view={addOnView} />;
                break;
            case 9:
                page = <RecipeBoard toggleFilterView={toggleFilterView} appliedFiltersInfo={appliedFiltersInfo} />;
                break;
        }
        return page;
    };

    const handleDragEnd = result => {
        const { destination, draggableId = '' } = result;
        if (!destination || draggedItems.indexOf(draggableId) !== -1) return;
        updateDragged([...draggedItems, draggableId]);
    };

    const setAddOnFunc = codeN => {
        updateIndex(8);
        setAddOnView(codeN);
    };

    const handleToolState = () => {
        if (toolOpen) {
            toggleTheme(false);
            updateDragged([]);
            setTheme('');
        }
        toggleTool(!toolOpen);
    };
    return (
        <Layout showHeader>
            <div
                className={c('diagnoseContent clearfix', {
                    pss_module: pss_module || isCfgClient,
                    isNonProfit,
                    hideAddOns,
                })}
            >
                {!isClient && (
                    <Button className="feedbackBtn" onClick={() => toggleFeedbackView(true)}>
                        <span>
                            <img src={PenWhite} alt="" />
                        </span>
                        {FEEDBACK}
                        <span>
                            <img src={SmallDown} alt="" />
                        </span>
                    </Button>
                )}
                <ul className={c({ isLoading: apiLoadingCount })}>
                    {NAV_LINKS.map(
                        (
                            {
                                imgSrc,
                                imgSrcActive,
                                index,
                                checkToShow,
                                checkForCohesion,
                                checkForAnalytics,
                                link,
                                checkForAddOns,
                                checkForNonProfit,
                                checkForIng,
                                checkForTalnx,
                                checkForOhi4,
                                hideforProfile,
                                checkToShowLatamClient,
                                checkForPercentile,
                                checkTabNonProfit,
                            },
                            mainIndex
                        ) => {
                            const text = HEALTH_NAV_LINKS[mainIndex];
                            if ((checkToShow || checkForCohesion) && pulseSurvey) return null;
                            if (checkForCohesion && isClient) return null;
                            if (checkForAnalytics && !isAdmin) return null;
                            if (checkForAddOns && isCfgClient) return null;
                            if (checkForNonProfit && isNonProfit) return null;
                            if (checkForIng && isIngClient) return null;
                            if (isClient && isTalnxClient && !checkForTalnx) return null;
                            if (checkForOhi4 && !showEmpExperience) return null;
                            if (hideforProfile) return null;
                            if (showTabLatam && checkToShowLatamClient) return null;
                            if (checkForPercentile && checkpercentFavourable) return null;
                            if (checkForAnalytics && !text_analytics_access) return null;
                            if (checkForAnalytics && isNonProfit) return null;
                            if (checkTabNonProfit && isNonProfitSurvey) return null;
                            if (link) {
                                return (
                                    <li key={text} data-testid={text} className="linkWrap">
                                        <a target="_blank" href={`${link}/?#jwt=${mIdToken}`} rel="noopener noreferrer">
                                            <img src={imgSrc} alt="" />
                                            {text}
                                        </a>
                                    </li>
                                );
                            }
                            if (index === 8) {
                                return <></>;
                            }
                            if (index === 9 && !isOhi4) {
                                return null;
                            }
                            return (
                                <li
                                    className={c({ selected: activeIndex === index, createTheme })}
                                    key={text}
                                    data-testid={text}
                                    onClick={() => updateIndex(index)}
                                >
                                    <img src={activeIndex === index ? imgSrcActive : imgSrc} alt="" />
                                    <span>{text}</span>
                                </li>
                            );
                        }
                    )}
                </ul>
                {showAdMenu && (
                    <ul className="dropdownMenu">
                        <li
                            className={c({ selected: activeIndex === 8, createTheme }, 'optionsList', 'addonMenu')}
                            key={HEALTH_NAV_LINKS[9].text}
                            data-testid={HEALTH_NAV_LINKS[9].text}
                            onMouseOver={() => showAddOnOptions(true)}
                            onMouseLeave={() => showAddOnOptions(false)}
                        >
                            <img src={activeIndex === 8 ? NAV_LINKS[10].imgSrcActive : NAV_LINKS[10].imgSrc} alt="" />
                            <span>{HEALTH_NAV_LINKS[9].text}</span>
                            {addOnOptions ? (
                                <div className="drop optionsPosition" id="optionsPosition">
                                    {addOnOpToUse().map(({ label, codeN, img }) => {
                                        return (
                                            <MenuItem
                                                classes={{ root: 'demographicOptions' }}
                                                key={codeN}
                                                onClick={() => setAddOnFunc(codeN)}
                                            >
                                                <img src={img} alt="" />
                                                {label}
                                            </MenuItem>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </li>
                    </ul>
                )}
                {(apiLoadingCount || reportApiCount || showProgressBar) && !showTabLatam ? (
                    <Loader position="absolute" showBackground background="transparent" />
                ) : null}

                {(apiLoadingCount || showProgressBar) && showTabLatam ? (
                    <Loader position="absolute" showBackground background="transparent" />
                ) : null}

                <DragDropContext onDragEnd={handleDragEnd}>
                    <section className={c('toolDataWrap', { toolOpen, createTheme, themeSelected })}>
                        <div className="toolBox">
                            {toolOpen && (
                                <Toolbox
                                    draggedItems={draggedItems}
                                    updateDragged={updateDragged}
                                    createTheme={createTheme}
                                    toggleTheme={toggleTheme}
                                    showCreateTheme={enableCreateTheme}
                                />
                            )}
                            <Button classes={{ root: 'toggleButton' }} onClick={handleToolState}>
                                {TOOLBOX} <ExpandMore />
                            </Button>
                        </div>
                        {getPage()}
                    </section>
                </DragDropContext>
                {filterOpen && (
                    <FilterBar
                        activeResetFilter={activeResetFilter}
                        size={surveyToUse}
                        surveyRespondents={[surveyToUse[1]]}
                        surveys={[surveyToUse[2]]}
                        filters={filters}
                        closeFilters={toggleFilterView}
                        setFilters={setFilters}
                        defaultBenchmark={benchmark}
                        defaultSettings={defaultSettings}
                        isEEpage={isEEpage}
                        showBenchmarks={activeIndex !== 9}
                    />
                )}
                {feedbackOpen && (
                    <Feedback
                        closeFeedback={toggleFeedbackView}
                        defaultSettings={defaultSettings}
                        apiLoadingCount={apiLoadingCount}
                    />
                )}
                {/* {!isClient ? <ChatBot userAuthToken={token} userName={userName} /> : null} */}
            </div>
        </Layout>
    );
}

Diagnose.propTypes = {
    themeSelected: PropTypes.string.isRequired,
    apiLoadingCount: PropTypes.number.isRequired,
    apiParams: PropTypes.object.isRequired,
    scoreData: PropTypes.object.isRequired,
    practiceData: PropTypes.object.isRequired,
    defaultSettings: PropTypes.object.isRequired,
    heatMapPractice: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    enableCreateTheme: PropTypes.bool.isRequired,
    scoreDataFetched: PropTypes.bool.isRequired,
    practiceDataFetched: PropTypes.bool.isRequired,
    activeResetFilter: PropTypes.bool.isRequired,
    heatMapPracticeFetched: PropTypes.bool.isRequired,
    heatMapDataFetched: PropTypes.bool.isRequired,
    cohesionDataFetched: PropTypes.bool.isRequired,
    heatMapData: PropTypes.object.isRequired,
    getBenchmarks: PropTypes.func.isRequired,
    getDemographics: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    setTheme: PropTypes.func.isRequired,
    demographicCurrentTab: PropTypes.string.isRequired,
    getCohesionData: PropTypes.func.isRequired,
    cohesionScore: PropTypes.number.isRequired,
    reportApiCount: PropTypes.number.isRequired,
    showProgressBar: PropTypes.bool.isRequired,
    localPracticeFetched: PropTypes.bool.isRequired,
    getLocalPractice: PropTypes.func.isRequired,
    targetBenchmarks: PropTypes.array.isRequired,
};

export default Diagnose;
