import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import CustomBenchmark from './CustomBenchmark';

// import {
//     updateLeftBenchmarks,
//     updateRightBenchmarks,
//     getScoreData,
//     updateApiParams,
//     getPptData,
// } from '../../Diagnose/actions';
// import { getFlagpole } from '../actions';
import {
    selectLeftBenchmarks,
    selectRightBenchmarks,
    selectApiParams,
    selectApiCount,
    selectFiltersApplied,
    getErrorInfo,
} from '../../Diagnose/selector';
import {
    selectCustomBmDetail,
    selectCustomBmList,
    selectCustomBmListFetched,
    selectCustomBmSurveyList,
    selectDataAtGlance,
    selectFilterParam,
    selectFilters,
    selectFiltersMapping,
    selectFlagpoleError,
    selectSaveCustomBmDraft,
    selectCreateCustomBmForm,
    selectCreateCustomBmFilter,
    selectCheckNameMsg,
    selectIsNameAvailable,
    selectSurveyListLoading,
    selectDefaultYear,
    selectCustomBmDefaultFilter,
} from '../selector';

import { getDefaultSettings } from '../../Login/selector';
import {
    getCustomBmList,
    getCustomBmSurveyList,
    saveCustomBmDraft,
    getCustomBmDetail,
    setCreateCustomBmPayload,
    setCustomBmFilter,
    deleteCustomBm,
    updateCustomBm,
    checkNameExist,
    setCustomBmMsg,
    sendCustomBmRequest,
} from '../actions';

const stateToProps = createStructuredSelector({
    customBmList: selectCustomBmList(),
    customBmListFetched: selectCustomBmListFetched(),
    leftBenchmarks: selectLeftBenchmarks(),
    rightBenchmarks: selectRightBenchmarks(),
    apiParams: selectApiParams(),
    apiLoadingCount: selectApiCount(),
    activeFilter: selectFiltersApplied(),
    errorInfo: getErrorInfo(),
    defaultSettings: getDefaultSettings(),
    filterParams: selectFilterParam(),
    flagpoleError: selectFlagpoleError(),
    dataAtGlance: selectDataAtGlance(),
    filters: selectFilters(),
    defaultFilters: selectCustomBmDefaultFilter(),
    filtersMapping: selectFiltersMapping(),
    customBmSurveyList: selectCustomBmSurveyList(),
    customBmMsg: selectSaveCustomBmDraft(),
    customBmDetail: selectCustomBmDetail(),
    data: selectCreateCustomBmForm(),
    customBmFilter: selectCreateCustomBmFilter(),
    defaultYear: selectDefaultYear(),
    checkNameMsg: selectCheckNameMsg(),
    isNameAvailable: selectIsNameAvailable(),
    surveyListLoading: selectSurveyListLoading(),
});

const dispatchToProps = {
    getCustomBmList,
    getCustomBmSurveyList,
    saveCustomBmDraft,
    getCustomBmDetail,
    setCustomBmFilter,
    setCreateCustomBmPayload,
    deleteCustomBm,
    updateCustomBm,
    checkNameExist,
    setCustomBmMsg,
    sendCustomBmRequest,
};

export default connect(stateToProps, dispatchToProps)(CustomBenchmark);
