import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import QuickHelp from '../QuickHelp/QuickHelp';
import CustomBenchmarkList from './CustomBenchmarkList';
import CreateCustomBenchmark from './CreateCustomBenchmark';
import CustomBmDetails from './CustomBmDetails';
import CustomBmEdit from './CustomBmEdit';
import WarningDialog from './WarningDialog';

function CustomBenchmark({
    defaultSettings,
    filterParams,
    filters,
    // defaultYear,
    renderfilterApplied,
    customBmList,
    customBmListFetched,
    getCustomBmList,
    defaultFilters,
    filtersMapping,
    getCustomBmSurveyList,
    customBmSurveyList,
    tab,
    setTab,
    saveCustomBmDraft,
    customBmMsg,
    getCustomBmDetail,
    customBmDetail,
    setCustomBmFilter,
    setCreateCustomBmPayload,
    data,
    customBmFilter,
    deleteCustomBm,
    updateCustomBm,
    checkNameExist,
    checkNameMsg,
    isNameAvailable,
    surveyListLoading,
    defaultYear,
    modal,
    viewModal,
    setCustomBmMsg,
    sendCustomBmRequest,
}) {
    // const { lang = '1033' } = defaultSettings || {};
    // const staticText = getStaticText(lang);
    // const { SITE_TEXT } = staticText;
    const [helpView, helpMode] = useState(false);
    // const [filter, setFilters] = useState(filters);
    const [customBmId, setCustomBmId] = useState('');
    const getPage = () => {
        switch (tab) {
            case 0:
                return (
                    <CustomBenchmarkList
                        defaultSettings={defaultSettings}
                        customBmList={customBmList}
                        customBmListFetched={customBmListFetched}
                        getCustomBmList={getCustomBmList}
                        filterParams={filterParams}
                        renderfilterApplied={renderfilterApplied}
                        tab={tab}
                        setTab={setTab}
                        setCustomBmId={setCustomBmId}
                        getCustomBmDetail={getCustomBmDetail}
                        deleteCustomBm={deleteCustomBm}
                        updateCustomBm={updateCustomBm}
                        customBmMsg={customBmMsg}
                        setSuccessToast={setCustomBmMsg}
                        sendCustomBmRequest={sendCustomBmRequest}
                    />
                );
            case 3:
                return (
                    <CreateCustomBenchmark
                        setTab={setTab}
                        defaultSettings={defaultSettings}
                        filters={filters}
                        defaultFilters={defaultFilters}
                        filtersMapping={filtersMapping}
                        getCustomBmSurveyList={getCustomBmSurveyList}
                        customBmSurveyList={customBmSurveyList}
                        filter={customBmFilter}
                        setFilters={setCustomBmFilter}
                        saveCustomBmDraft={saveCustomBmDraft}
                        customBmMsg={customBmMsg}
                        data={data}
                        setData={setCreateCustomBmPayload}
                        checkNameExist={checkNameExist}
                        checkNameMsg={checkNameMsg}
                        isNameAvailable={isNameAvailable}
                        surveyListLoading={surveyListLoading}
                        defaultYear={defaultYear}
                    />
                );
            case 4:
                return (
                    <CustomBmDetails
                        defaultSettings={defaultSettings}
                        setTab={setTab}
                        customBmDetail={customBmDetail}
                        customBmFilter={customBmFilter}
                        customBmId={customBmId}
                        defaultFilters={defaultFilters}
                        sendCustomBmRequest={sendCustomBmRequest}
                    />
                );
            case 5:
                return (
                    <CustomBmEdit
                        setTab={setTab}
                        defaultSettings={defaultSettings}
                        filters={filters}
                        defaultFilters={defaultFilters}
                        filtersMapping={filtersMapping}
                        getCustomBmSurveyList={getCustomBmSurveyList}
                        customBmSurveyList={customBmSurveyList}
                        filter={customBmFilter}
                        setFilters={setCustomBmFilter}
                        saveCustomBmDraft={saveCustomBmDraft}
                        customBmMsg={customBmMsg}
                        data={data}
                        setData={setCreateCustomBmPayload}
                        updateCustomBm={updateCustomBm}
                        customBmDetail={customBmDetail}
                        checkNameExist={checkNameExist}
                        checkNameMsg={checkNameMsg}
                        isNameAvailable={isNameAvailable}
                        surveyListLoading={surveyListLoading}
                        defaultYear={defaultYear}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Fragment>
            <div className="customBenchmark clearfix">
                {getPage()}
                {modal ? <WarningDialog setView={viewModal} /> : null}
                <QuickHelp helpMode={helpMode} helpView={helpView} activePage="3" />
            </div>
        </Fragment>
    );
}

CustomBenchmark.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    filterParams: PropTypes.string.isRequired,
    renderfilterApplied: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    customBmList: PropTypes.array.isRequired,
    customBmListFetched: PropTypes.bool.isRequired,
    getCustomBmList: PropTypes.func.isRequired,
    defaultFilters: PropTypes.array.isRequired,
    filtersMapping: PropTypes.array.isRequired,
    getCustomBmSurveyList: PropTypes.func.isRequired,
    customBmSurveyList: PropTypes.array.isRequired,
    tab: PropTypes.number.isRequired,
    setTab: PropTypes.func.isRequired,
    saveCustomBmDraft: PropTypes.func.isRequired,
    customBmMsg: PropTypes.string.isRequired,
    getCustomBmDetail: PropTypes.func.isRequired,
    customBmDetail: PropTypes.object.isRequired,
    setCustomBmFilter: PropTypes.func.isRequired,
    setCreateCustomBmPayload: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    customBmFilter: PropTypes.array.isRequired,
    deleteCustomBm: PropTypes.func.isRequired,
    updateCustomBm: PropTypes.func.isRequired,
    // defaultYear: PropTypes.number.isRequired,
    checkNameExist: PropTypes.func.isRequired,
    checkNameMsg: PropTypes.string.isRequired,
    isNameAvailable: PropTypes.bool.isRequired,
    surveyListLoading: PropTypes.bool.isRequired,
    defaultYear: PropTypes.number.isRequired,
    modal: PropTypes.bool.isRequired,
    viewModal: PropTypes.func.isRequired,
    setCustomBmMsg: PropTypes.func.isRequired,
    sendCustomBmRequest: PropTypes.func.isRequired,
};

export default CustomBenchmark;
