import React, { Fragment } from 'react';
import { Dialog } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoLarge from '../assets/InfoLarge.svg';
import SelectBenchmark from '../../../components/SelectBenchmark/SelectBenchmark';

const WarningDialog = ({ setView, benchmarks, optionsToUse, setOptionsToUse, targetBm, updateTargetSettingBm }) => {
    // useEffect(() => {
    //     if (id) {
    //         setTicketId(id);
    //     }
    //     // eslint-disable-next-line
    // }, [feedback]);

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'feedbackSuccessDialogRoot warningDialogRoot',
            }}
        >
            <section className="dataContent dataHead">
                <div className="infoLarge">
                    <img src={InfoLarge} alt="" />
                </div>
                <h2>Global or associated region benchmark is used for associated target setting!</h2>
                {/* <div className="nRes">
                    {'Jira ticket ID : '} <b>{jira_number}</b>
                </div> */}
                <div className="para">
                    <div>
                        Practice and outcome targets are set against global or region benchmarks. Anchoring on the
                        global benchmark is recommended as best practice. Regional benchmarks are useful only when local
                        factors affect scores. Sub-benchmarks are more volatile, which makes it difficult to accurately
                        project the point increases required to achieve subsequent quartiles year-over-year.
                    </div>
                    <div>
                        The tool will display outcome and practice targets to:
                        <ul>
                            <li>
                                Global benchmark if McKinsey Sector level benchmarks or Specialty/Custom benchmarks are
                                selected.
                            </li>
                            <li>Region benchmark if Country benchmark or Sub-regional benchmark is selected”</li>
                        </ul>
                    </div>
                    <div>
                        <SelectBenchmark
                            benchmarks={benchmarks}
                            updateTargetSettingBm={updateTargetSettingBm}
                            targetBm={targetBm}
                            optionsToUse={optionsToUse}
                            setOptionsToUse={setOptionsToUse}
                            showLabel
                        />
                    </div>
                </div>
            </section>

            <section className="buttonContent clearfix activeButtons">
                <Fragment>
                    <div
                        data-testid="compareButton"
                        className="compareBtn activeCompareBtn"
                        onClick={() => {
                            setView(false);
                            updateTargetSettingBm(optionsToUse);
                        }}
                    >
                        Continue
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

WarningDialog.propTypes = {
    setView: PropTypes.func.isRequired,
    benchmarks: PropTypes.array.isRequired,
    optionsToUse: PropTypes.array.isRequired,
    setOptionsToUse: PropTypes.func.isRequired,
    targetBm: PropTypes.string.isRequired,
    updateTargetSettingBm: PropTypes.func.isRequired,
};

export default WarningDialog;
