import { call, put, takeLatest } from 'redux-saga/effects';
import {
    getAccessDetailsSuccess,
    getAccessDetailsFailed,
    updatePasswordSuccess,
    updatePasswordFailed,
    getPptAccessDetails,
    getPptAccessDetailsSuccess,
    getPptAccessDetailsFailed,
    getDefaultSettingsSuccess,
    getDefaultSettingsFailed,
} from './actions';
import { GET_ACCESS_DETAILS, GET_PPT_ACCESS_DETAILS, UPDATE_PASSWORD, GET_DEFAULT_SETTINGS } from './constants';
import { getAccessApiUrl, getPptAccessApiUrl, changePasswordApiUrl, getDefaultSettingsUrl } from './apis';

import AuthStore from '../../common/AuthStore';
import { parseJwt } from '../../utils/functions';

export function* getAccessDetails({ payload }) {
    try {
        const { data = {} } = yield call(getAccessApiUrl, payload);
        const { data: accessData = {} } = data;
        const { token = '', user = {}, action_access = false, GSOD = {} } = accessData;
        const { name = '', role = '', email = '' } = user;
        const { is_access: gsodAccess = false, access_role = '' } = GSOD;
        const { access_token = '' } = payload;
        AuthStore.accessToken = `Bearer ${token}`;
        AuthStore.mIdToken = access_token;
        AuthStore.userRole = role;
        AuthStore.userName = name;
        AuthStore.userEmail = email;
        AuthStore.actionAccess = action_access;
        AuthStore.gsodAccess = gsodAccess;
        AuthStore.showFeatureModal = true;
        AuthStore.gsodRole = access_role;
        if (window.heap) {
            window.heap.identify(name);
            window.heap.addUserProperties({
                role,
                email,
            });
        }
        yield put(getAccessDetailsSuccess({ ...user, token }));
        yield put(getPptAccessDetails());
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getAccessDetailsFailed({ message, error, status }));
    }
}

export function* getPptAccessSaga() {
    try {
        const { data: pptAccessData = {} } = yield call(getPptAccessApiUrl);
        const { data = {} } = pptAccessData;
        const { id_token, token_type } = data;
        const accessToken = `${token_type} ${id_token}`;
        const { exp = 0 } = parseJwt(accessToken);
        AuthStore.pptAccessToken = accessToken;
        AuthStore.pptTokenExp = exp;
        yield put(getPptAccessDetailsSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getPptAccessDetailsFailed({ message, error, status }));
    }
}

export function* updatePasswordSaga({ payload, token }) {
    try {
        const { data = {} } = yield call(changePasswordApiUrl, payload, token);
        const { data: userData = {} } = data;
        yield put(updatePasswordSuccess(userData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(updatePasswordFailed({ message, error, status }));
    }
}

export function* getDefaultSettingsSaga({ payload }) {
    try {
        const { data = {} } = yield call(getDefaultSettingsUrl, payload);
        const { data: settingsData = {} } = data;
        yield put(getDefaultSettingsSuccess(settingsData));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getDefaultSettingsFailed({ message, error, status }));
    }
}

const loginSagas = [
    takeLatest(`${GET_ACCESS_DETAILS}_PENDING`, getAccessDetails),
    takeLatest(`${GET_PPT_ACCESS_DETAILS}_PENDING`, getPptAccessSaga),
    takeLatest(`${UPDATE_PASSWORD}_PENDING`, updatePasswordSaga),
    takeLatest(`${GET_DEFAULT_SETTINGS}_PENDING`, getDefaultSettingsSaga),
];

export default loginSagas;
