import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
    getDataAtGlanceFailed,
    getDataAtGlanceSuccess,
    getFilterDataFailed,
    getFilterDataSuccess,
    getScorecardFailed,
    getScorecardSuccess,
    getAnalyticsPerCategoryFailed,
    getAnalyticsPerCategorySuccess,
    getRankingFailed,
    getRankingSuccess,
    getFlagpoleSuccess,
    getFlagpoleFailed,
    getDifferentialSuccess,
    getDifferentialFailed,
    getFiltersMappingSuccess,
    getFiltersMappingFailed,
    getDefaultYearSuccess,
    getDefaultYearFailed,
    setOrgSizeData,
    getMedianSuccess,
    getMedianFailed,
    getDifferentialQuartileSuccess,
    getDifferentialQuartileFailed,
    showQuatileTabBtn,
    getDifferentialMedianSuccess,
    getDifferentialMedianFailed,
    showMedianTabBtn,
    getCustomBmListSuccess,
    getCustomBmListFailed,
    getCustomBmSurveyListSuccess,
    getCustomBmSurveyListFailed,
    saveCustomBmDraftSuccess,
    saveCustomBmDraftFailed,
    getCustomBmDetailSuccess,
    getCustomBmDetailFailed,
    deleteCustomBmSuccess,
    deleteCustomBmFailed,
    updateCustomBmSuccess,
    updateCustomBmFailed,
    checkNameExistSuccess,
    checkNameExistFailed,
    sendCustomBmRequestSuccess,
    sendCustomBmRequestFailed,
} from './actions';
import { getStaticTextAnalytics } from './constants';
import {
    getAnalyticsDataUrl,
    getFilterDataUrl,
    getScorecardUrl,
    getAnalyticsPerCategoryUrl,
    getFlagpoleUrl,
    getAnalyticsRankingUrl,
    getDifferentialUrl,
    getFilterMappingUrl,
    getDefaultYearUrl,
    getMedianUrl,
    getCustomBmListUrl,
    getCustomBmSurveyListUrl,
    saveCustomBmDraft,
    getCustomBmDetails,
    deleteCustomBenchmark,
    updateCustomBenchmark,
    checkNameExist,
    sendCustomBmRequest,
} from './apis';
import { getMaxRange, removeQuartileFromFilterParams } from './assets/functions';

const staticTextAnalytics = getStaticTextAnalytics();
const {
    GET_DATA_AT_GLANCE,
    GET_FILTERS_DATA,
    GET_ANALYTICS_SCORECARD,
    GET_ANALYTICS_FLAGPOLE,
    GET_ANALYTICS_CATEGORY,
    GET_RANKING,
    GET_DIFFERENTIAL,
    GET_FILTERS_MAPPING,
    GET_DEFAULT_YEAR,
    GET_MEDIAN,
    GET_DIFFERENTIAL_QUARTILE,
    GET_DIFFERENTIAL_MEDIAN,
    GET_CUSTOM_BM_LIST,
    GET_CUSTOM_BM_SURVEY_LIST,
    SAVE_CUSTOM_BM,
    GET_CUSTOM_BM_DETAIL,
    DELETE_CUSTOM_BM,
    UPDATE_CUSTOM_BM,
    CHECK_NAME_EXIST,
    SEND_CUSTOM_BM_REQUEST,
} = staticTextAnalytics;

export function* filtersDataSaga({ payload }) {
    try {
        const { data = [] } = yield call(getFilterDataUrl, payload);
        const sortedData = data.sort(({ display_order: a }, { display_order: b }) => a - b);
        const year = `${payload}`;
        if (sortedData.length) {
            const filterYearIndex = sortedData.findIndex(({ filter_id }) => filter_id === 'year');
            const filterYearIndex1 = sortedData.findIndex(({ filter_id }) => filter_id === 'survey_year');
            const { filter_items = [] } = sortedData[filterYearIndex];
            const { filter_items: filterItems = [] } = sortedData[filterYearIndex1];
            const latestYrIndex = filter_items.findIndex(({ filter_item_id }) => filter_item_id === payload);
            filter_items.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                if (parseInt(filter_item_name, 10) >= 2023) {
                    sortedData[filterYearIndex].filter_items[i].allowSelect = true;
                }
            });
            sortedData[filterYearIndex].isSelected = true;
            sortedData[filterYearIndex].isDefault = true;
            sortedData[filterYearIndex].filter_items[latestYrIndex].isSelected = true;
            const max_range = getMaxRange(sortedData) || '';
            yield put(setOrgSizeData([0, max_range]));

            filterItems.forEach((item, i) => {
                const { filter_item_name = '' } = item;
                const yearItem = parseInt(filter_item_name, 10);
                if (year === '2024')
                    if (yearItem < 2024 && yearItem >= 2019) {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = true;
                    } else {
                        sortedData[filterYearIndex1].filter_items[i].allowSelect = false;
                    }
                sortedData[filterYearIndex1].isSurveyDefault = true;
            });
            // sortedData[filterYearIndex1].isSurveyDefault = true;
        }
        yield put(getFilterDataSuccess({ data: sortedData, year }));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getFilterDataFailed({ message, error, status }));
    }
}

export function* filtersMappingSaga({ payload }) {
    try {
        const { data = {} } = yield call(getFilterMappingUrl, payload);
        yield put(getFiltersMappingSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getFiltersMappingFailed({ message, error, status }));
    }
}

export function* dataAtGlanceSaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsDataUrl, payload);
        yield put(getDataAtGlanceSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getDataAtGlanceFailed({ message, error, status }));
    }
}

export function* differentialDataSaga({ payload }) {
    const { filters, remove_threshold } = payload;
    const newPayload = removeQuartileFromFilterParams(filters);
    const newPayload1 = { filters: newPayload, remove_threshold };
    try {
        const { data = {} } = yield call(getDifferentialUrl, newPayload1);
        yield put(getDifferentialSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getDifferentialFailed({ message, errors, status }));
    }
}

export function* quartileDataSaga({ payload }) {
    const { filters, remove_threshold } = payload;
    const newPayload = removeQuartileFromFilterParams(filters);
    const newPayload1 = { filters: newPayload, remove_threshold };
    yield put(showQuatileTabBtn(true));
    try {
        const { data = {} } = yield call(getDifferentialUrl, newPayload1);
        yield put(getDifferentialQuartileSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getDifferentialQuartileFailed({ message, errors, status }));
    }
}

export function* medianDataSaga({ payload }) {
    const { filters, remove_threshold } = payload;
    const newPayload = removeQuartileFromFilterParams(filters);
    const newPayload1 = { filters: newPayload, remove_threshold };
    try {
        const { data = {} } = yield call(getMedianUrl, newPayload1);
        yield put(getMedianSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getMedianFailed({ message, errors, status }));
    }
}

export function* differentiateMedianDataSaga({ payload }) {
    const { filters, remove_threshold } = payload;
    const newPayload = removeQuartileFromFilterParams(filters);
    const newPayload1 = { filters: newPayload, remove_threshold };
    yield put(showMedianTabBtn(true));
    try {
        const { data = {} } = yield call(getMedianUrl, newPayload1);
        yield put(getDifferentialMedianSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getDifferentialMedianFailed({ message, errors, status }));
    }
}

export function* rankingDataSaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsRankingUrl, payload);
        yield put(getRankingSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getRankingFailed({ message, errors, status }));
    }
}

export function* analyticsPerCategorySaga({ payload }) {
    try {
        const { data = {} } = yield call(getAnalyticsPerCategoryUrl, payload);
        const state = yield select();
        const analyticsData = state.get('analytics').toJS() || {};
        const { analyticsPerCategoryData = {} } = analyticsData || {};
        const { filter_id = '' } = payload || {};
        const newData = {
            ...analyticsPerCategoryData,
            [filter_id]: data,
        };
        yield put(getAnalyticsPerCategorySuccess(newData));
    } catch ({ response = {} }) {
        const state = yield select();
        const analyticsData = state.get('analytics').toJS() || {};
        const { analyticsPerCategoryData = {} } = analyticsData || {};
        const { filter_id = '' } = payload || {};
        const newData = {
            ...analyticsPerCategoryData,
            [filter_id]: {},
        };
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getAnalyticsPerCategoryFailed({ message, error, status, data: newData }));
    }
}

export function* flagpoleSaga({ payload }) {
    try {
        const { data = {} } = yield call(getFlagpoleUrl, payload);
        yield put(getFlagpoleSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getFlagpoleFailed({ message, errors, status }));
    }
}

export function* scorecardSaga({ payload }) {
    try {
        const { data = {} } = yield call(getScorecardUrl, payload);
        yield put(getScorecardSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getScorecardFailed({ message, errors, status }));
    }
}

export function* defaultYearSaga({ payload }) {
    try {
        const { data } = yield call(getDefaultYearUrl, payload);
        const { default_year } = data;
        yield put(getDefaultYearSuccess(default_year));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, error = '' } = errorData;
        yield put(getDefaultYearFailed({ message, error, status }));
    }
}

export function* customBenchmarkListSaga({ payload }) {
    try {
        const { data = {} } = yield call(getCustomBmListUrl, payload);
        yield put(getCustomBmListSuccess(data.data.records));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getCustomBmListFailed({ message, errors, status }));
    }
}

export function* customBmSurveyListSaga({ payload }) {
    try {
        const { data = {} } = yield call(getCustomBmSurveyListUrl, payload);
        yield put(getCustomBmSurveyListSuccess(data.data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getCustomBmSurveyListFailed({ message, errors, status }));
    }
}

export function* saveCustomBmSaga({ payload }) {
    try {
        const { data } = yield call(saveCustomBmDraft, payload);
        const { message = '' } = data || {};
        yield put(saveCustomBmDraftSuccess(message));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(saveCustomBmDraftFailed({ message, errors, status }));
    }
}

export function* customBenchmarkDetailsSaga({ payload }) {
    try {
        const { data = {} } = yield call(getCustomBmDetails, payload);
        yield put(getCustomBmDetailSuccess(data.data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(getCustomBmDetailFailed({ message, errors, status }));
    }
}

export function* deleteCustomBmSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(deleteCustomBenchmark, payload);
        yield put(deleteCustomBmSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(deleteCustomBmFailed({ message, errors, status }));
    }
}

export function* updateCustomBmSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(updateCustomBenchmark, payload);
        yield put(updateCustomBmSuccess(data.data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(updateCustomBmFailed({ message, errors, status }));
    }
}

export function* checkNameExistSaga({ payload = {} }) {
    // debugger
    try {
        const { data = {} } = yield call(checkNameExist, payload);
        yield put(checkNameExistSuccess(data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(checkNameExistFailed({ message, errors, status }));
    }
}

export function* sendCustomBmReqSaga({ payload = {} }) {
    try {
        const { data = {} } = yield call(sendCustomBmRequest, payload);
        yield put(sendCustomBmRequestSuccess(data.data));
    } catch ({ response = {} }) {
        const { data: errorData = {}, status = '' } = response;
        const { message = {}, errors = '' } = errorData;
        yield put(sendCustomBmRequestFailed({ message, errors, status }));
    }
}

const analyticsSagas = [
    takeLatest(`${GET_FILTERS_DATA}_PENDING`, filtersDataSaga),
    takeLatest(`${GET_FILTERS_MAPPING}_PENDING`, filtersMappingSaga),
    takeLatest(`${GET_DATA_AT_GLANCE}_PENDING`, dataAtGlanceSaga),
    takeLatest(`${GET_DIFFERENTIAL}_PENDING`, differentialDataSaga),
    takeLatest(`${GET_RANKING}_PENDING`, rankingDataSaga),
    takeLatest(`${GET_ANALYTICS_CATEGORY}_PENDING`, analyticsPerCategorySaga),
    takeLatest(`${GET_ANALYTICS_FLAGPOLE}_PENDING`, flagpoleSaga),
    takeLatest(`${GET_ANALYTICS_SCORECARD}_PENDING`, scorecardSaga),
    takeLatest(`${GET_DEFAULT_YEAR}_PENDING`, defaultYearSaga),
    takeLatest(`${GET_MEDIAN}_PENDING`, medianDataSaga),
    takeLatest(`${GET_DIFFERENTIAL_QUARTILE}_PENDING`, quartileDataSaga),
    takeLatest(`${GET_DIFFERENTIAL_MEDIAN}_PENDING`, differentiateMedianDataSaga),
    takeLatest(`${GET_CUSTOM_BM_LIST}_PENDING`, customBenchmarkListSaga),
    takeLatest(`${GET_CUSTOM_BM_SURVEY_LIST}_PENDING`, customBmSurveyListSaga),
    takeLatest(`${SAVE_CUSTOM_BM}_PENDING`, saveCustomBmSaga),
    takeLatest(`${GET_CUSTOM_BM_DETAIL}_PENDING`, customBenchmarkDetailsSaga),
    takeLatest(`${DELETE_CUSTOM_BM}_PENDING`, deleteCustomBmSaga),
    takeLatest(`${UPDATE_CUSTOM_BM}_PENDING`, updateCustomBmSaga),
    takeLatest(`${CHECK_NAME_EXIST}_PENDING`, checkNameExistSaga),
    takeLatest(`${SEND_CUSTOM_BM_REQUEST}_PENDING`, sendCustomBmReqSaga),
];

export default analyticsSagas;
