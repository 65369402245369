import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import './index.scss';
import { Accordion, AccordionDetails, MenuItem, Select, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { KeyboardArrowDownSharp } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
// import InfoIcon from '../../Diagnose/assets/info-feedback.svg';
import ArrowBack from '../../Diagnose/assets/Small-left.svg';
import ArrowForward from '../../Diagnose/assets/Small-right.svg';
import ArrowDoubleBack from '../../Diagnose/assets/Double-arrow-left.svg';
import ArrowDoubleForward from '../../Diagnose/assets/Double-arrow-right.svg';
import CloudImage from '../assets/InsufficientData1.svg';
// import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
// import FilterSvg from '../../Diagnose/assets/Filter.svg';
// import Down from '../../Diagnose/assets/Down.svg';
import { getStaticText } from '../../../utils/constants';
import { ACTION_BTNS, STATUS_ICONS, ACTION_BTNS_2 } from '../constants';
import SuccessToast from '../../../components/CustomToast/SuccessToast';
import PublishModal from './PublishModal';
// import { getStaticTextAnalytics } from '../constants';

function CustomBenchmarkList({
    // getFeedbackList,
    defaultSettings,
    // setTicketId,
    // setView,
    tab,
    // refreshFeedbackList,
    customBmList,
    customBmListFetched,
    getCustomBmList,
    filterParams,
    // renderfilterApplied,
    setTab,
    // filtersApplied,
    // toggleFilterView,
    // setCustomBmId,
    getCustomBmDetail,
    deleteCustomBm,
    // updateCustomBm,
    customBmMsg,
    setSuccessToast,
    sendCustomBmRequest,
}) {
    const { lang = '1033' } = defaultSettings || {};
    // const staticTextAnalytics = getStaticTextAnalytics(lang);
    // const { ANALYTICS_QUARTILE_COLORS, FLAGPOLE_LINK } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText || {};
    const { ANALYTIC_CUSTOM_BM_TEXT, CUSTOM_BM_ANALYSIS_TABS } = SITE_TEXT || {};
    const [page, setPage] = useState(1);
    const [entryCount, setEntryCount] = useState(10);
    const [publishModal, setPublishModal] = useState(false);
    const [customBmSelected, setCustomBmSelected] = useState({});
    const { id: customBmId = '', name: customBmName = '' } = customBmSelected || {};

    const FEEDBACK_LIST_TABLE_HEADING = [
        {
            id: 'name',
            label: 'Custom Benchmark Name',
        },
        {
            id: 'no_of_surveys',
            label: 'No. of Surveys',
        },
        {
            id: 'no_of_responses',
            label: 'No. of Respondents',
        },
        {
            id: 'attached_surveys',
            label: 'Attached to',
        },
        {
            id: 'status',
            label: 'Status',
        },
        {
            id: 'created_on',
            label: 'Created on',
        },
        {
            id: 'action',
            label: 'Action',
        },
    ];

    useEffect(() => {
        if (!customBmListFetched) {
            getCustomBmList({
                limit: 10,
                page: 1,
                filters: filterParams,
            });
        }
        // eslint-disable-next-line
    }, [customBmListFetched]);

    const lastPage = Math.ceil(customBmList.length / entryCount);

    const renderStatusIcon = type => {
        const { imgSrc } = STATUS_ICONS.find(({ statusType }) => statusType === type) || {};
        return (
            <span>
                <img src={imgSrc} alt="" />
            </span>
        );
    };

    const getActions = status => {
        if (status === 'Awaiting Approval') {
            return ACTION_BTNS_2;
        }
        return ACTION_BTNS;
    };

    return (
        <div className="customBmList">
            <Helmet>
                <title>{ANALYTIC_CUSTOM_BM_TEXT['1']} </title>
            </Helmet>
            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="mainHeading">{ANALYTIC_CUSTOM_BM_TEXT['2']}</div>
                    {/* <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${n_survey}`}</div> */}
                </div>
                <ul className="navScoreList">
                    <li className="nRes" onClick={() => setTab(3)}>
                        Create Custom Benchmark
                    </li>
                    {/* <li onClick={() => toggleFilterView(true)} data-testid="filterButton">
                        <img src={!filtersApplied ? FilterSvg : ActiveFilter} alt="" />{' '}
                        <span className="filter">{FILTERS}</span>
                    </li> */}
                </ul>
            </div>
            {/* {renderfilterApplied} */}
            <div className="optionWrapper">
                <div className="differentiatorToggle">
                    <ul className="leftNav">
                        {CUSTOM_BM_ANALYSIS_TABS.map((tabName, index) => (
                            <li className={c({ activeLink: tab === index })} onClick={() => setTab(index)}>
                                {tabName}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="rightOptions">
                    {/* <div>
                        <ul className="rightSideNav">
                            <li className="strong">{`Number of Surveys (n) = ${n_survey}`}</li>
                            <li
                                onClick={() => toggleFilterView(true)}
                                data-testid="filterButton"
                                className="filterButton"
                            >
                                <img src={filtersApplied ? ActiveFilter : FilterSvg} alt="" />
                                {FILTERS}
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
            <div className="userReportsContent">
                <Accordion classes={{ root: 'accordionRoot' }} key="table" expanded>
                    <AccordionDetails classes={{ root: 'clearfix' }}>
                        <table className="userSection">
                            <tbody className="userContent">
                                <thead className="headerSection">
                                    {FEEDBACK_LIST_TABLE_HEADING.map(({ label, id }) => (
                                        <th className="headerHeading" key={id}>
                                            {label}
                                            {id === 'status' ? (
                                                <span className="infoIcon">
                                                    <Tooltip
                                                        // title="Ticket Journey: <br/> 1. To Do"
                                                        classes={{ popper: 'tooltip' }}
                                                        title={
                                                            <span>
                                                                Ticket Journey: <br /> 1. To Do <br /> 2. In Progress{' '}
                                                                <br /> 3. QA Ready <br /> 4. Code Review <br /> 5. Done{' '}
                                                            </span>
                                                        }
                                                    >
                                                        <span />
                                                        {/* <img src={InfoIcon} alt="" /> */}
                                                    </Tooltip>
                                                </span>
                                            ) : null}
                                        </th>
                                    ))}
                                </thead>

                                {!!customBmList.length &&
                                    customBmList
                                        .slice((page - 1) * entryCount, page * entryCount)
                                        .map(
                                            (
                                                {
                                                    created_on = '',
                                                    name = '',
                                                    no_of_surveys = '',
                                                    no_of_responses = '',
                                                    attached_surveys = '',
                                                    status = '',
                                                    id = '',
                                                },
                                                indexRep
                                            ) => {
                                                return (
                                                    <tr key={created_on} className="userDataSection">
                                                        <td className="userTableContent">{name}</td>
                                                        <td className="userTableContent">{no_of_surveys}</td>
                                                        <td className="userTableContent">{no_of_responses}</td>
                                                        <td className="userTableContent">{attached_surveys}</td>
                                                        <td className="userTableContent status">
                                                            <span
                                                                className={c({
                                                                    draft: status === 'Draft',
                                                                    awaiting: status === 'Awaiting Approval',
                                                                })}
                                                            >
                                                                {renderStatusIcon(status)}
                                                                {status}
                                                            </span>
                                                        </td>
                                                        <td className="userTableContent">
                                                            {moment(created_on).format('DD-MMM-YYYY')}
                                                        </td>
                                                        <td
                                                            data-testid={`reportTab${indexRep}`}
                                                            className="arrowContent"
                                                        >
                                                            <div
                                                                className="reportView"
                                                                // onClick={() => changeView(ticket_no)}
                                                            >
                                                                {getActions(status).map(({ imgSrc, code }) => {
                                                                    return (
                                                                        <span
                                                                            onClick={() => {
                                                                                if (code === 4) {
                                                                                    getCustomBmDetail(id);
                                                                                    setTab(4);
                                                                                } else if (code === 8) {
                                                                                    deleteCustomBm(id);
                                                                                } else if (code === 7) {
                                                                                    getCustomBmDetail(id);
                                                                                    setTab(5);
                                                                                } else if (code === 6) {
                                                                                    setPublishModal(true);
                                                                                    setCustomBmSelected({
                                                                                        id,
                                                                                        name,
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            <img src={imgSrc} alt="" />
                                                                        </span>
                                                                    );
                                                                })}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                {!customBmList.length ? (
                                    <div className="emptyList">
                                        <div>
                                            <h3>No survey list available</h3>
                                            <img src={CloudImage} alt="" />
                                        </div>
                                    </div>
                                ) : null}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
                {customBmList.length > entryCount ? (
                    <div className="pagination">
                        <span onClick={() => setPage(1)} className={c({ inActive: page === 1 }, 'arrowBtn')}>
                            <img src={ArrowDoubleBack} alt="" />
                        </span>
                        <span onClick={() => setPage(page - 1)} className={c({ inActive: page === 1 }, 'arrowBtn')}>
                            <img src={ArrowBack} alt="" />
                        </span>
                        {Array(lastPage)
                            .fill(0)
                            .map((_, index) => {
                                return (
                                    <span key={index + 1} className={c({ activePage: page === index + 1 })}>
                                        {index + 1}
                                    </span>
                                );
                            })}
                        <span
                            onClick={() => setPage(page + 1)}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowForward} alt="" />
                        </span>
                        <span
                            onClick={() => setPage(lastPage)}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowDoubleForward} alt="" />
                        </span>
                        <span className="resultsPerPage">
                            Results Per Page{' '}
                            <span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharp}
                                    value={entryCount}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target }) => {
                                        setEntryCount(target.value);
                                    }}
                                >
                                    {[10, 11, 12].map(pageItem => {
                                        return (
                                            <MenuItem
                                                classes={{ root: 'demographicOptions' }}
                                                key={pageItem}
                                                value={pageItem}
                                            >
                                                {pageItem}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </span>
                        </span>
                    </div>
                ) : null}
                {customBmMsg ? <SuccessToast closeView={() => setSuccessToast('')} message={customBmMsg} /> : null}
            </div>
            {publishModal ? (
                <PublishModal
                    setView={setPublishModal}
                    sendPublishRequest={sendCustomBmRequest}
                    customBmId={customBmId}
                    customBmName={customBmName}
                />
            ) : null}
        </div>
    );
}

CustomBenchmarkList.propTypes = {
    getCustomBmList: PropTypes.func.isRequired,
    customBmList: PropTypes.array.isRequired,
    defaultSettings: PropTypes.func.isRequired,
    tab: PropTypes.number.isRequired,
    // refreshFeedbackList: PropTypes.func.isRequired,
    customBmListFetched: PropTypes.bool.isRequired,
    filterParams: PropTypes.string.isRequired,
    // renderfilterApplied: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    getCustomBmDetail: PropTypes.func.isRequired,
    deleteCustomBm: PropTypes.func.isRequired,
    customBmMsg: PropTypes.string.isRequired,
    setSuccessToast: PropTypes.func.isRequired,
    sendCustomBmRequest: PropTypes.func.isRequired,
};

export default CustomBenchmarkList;
