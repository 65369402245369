import React from 'react';
import PropTypes from 'prop-types';
import Check from '../../containers/Diagnose/assets/C-check.svg';
import Close from '../../assets/Close.svg';
import './index.scss';

const SuccessToast = ({ message, closeView }) => {
    return (
        <div className="toastRoot">
            <div className="toastContent successToast">
                <div className="iconContainer">
                    <img src={Check} alt="loading" />
                </div>
                <div className="textContainer">
                    <div className="head">Please wait a moment</div>
                    <div className="desc">
                        {/* Survey list according to the selected filter is getting ready for further selection. */}
                        {message}
                    </div>
                </div>
                <div className="closeIcon">
                    <img src={Close} alt="" onClick={closeView} />
                </div>
            </div>
        </div>
    );
};

SuccessToast.propTypes = {
    message: PropTypes.string.isRequired,
    closeView: PropTypes.func.isRequired,
};

export default SuccessToast;
