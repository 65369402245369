import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import './index.scss';
import { Accordion, AccordionDetails, MenuItem, Select, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { KeyboardArrowDownSharp } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
// import InfoIcon from '../../Diagnose/assets/info-feedback.svg';
import ArrowBackSm from '../../Diagnose/assets/Small-left.svg';
import ArrowForward from '../../Diagnose/assets/Small-right.svg';
import ArrowDoubleBack from '../../Diagnose/assets/Double-arrow-left.svg';
import ArrowDoubleForward from '../../Diagnose/assets/Double-arrow-right.svg';
import CloudImage from '../assets/InsufficientData1.svg';
import Paper from '../assets/paper.svg';
import Detail from '../assets/detail.svg';
import Down from '../../Diagnose/assets/Down.svg';
import Edit from '../assets/Edit.svg';
import Publish from '../assets/upload.svg';
// import ActiveFilter from '../../Diagnose/assets/ActiveFilters1.svg';
// import FilterSvg from '../../Diagnose/assets/Filter.svg';
// import Down from '../../Diagnose/assets/Down.svg';
import { getStaticText } from '../../../utils/constants';
import { STATUS_ICONS } from '../constants';
// import { getStaticTextAnalytics } from '../constants';
// import colors from '../../../sass/colors';
import { getFilterArrFromFilterStr } from '../assets/functions';
import PublishModal from './PublishModal';

function CustomBmDetails({
    defaultSettings,
    // customBmList,
    setTab,
    customBmDetail,
    defaultFilters,
    sendCustomBmRequest,
}) {
    const { lang = '1033' } = defaultSettings || {};
    // const staticTextAnalytics = getStaticTextAnalytics(lang);
    // const { ANALYTICS_QUARTILE_COLORS, FLAGPOLE_LINK } = staticTextAnalytics;
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText || {};
    const { ANALYTIC_CUSTOM_BM_TEXT, FILTERS_APPLIED } = SITE_TEXT || {};
    const [page, setPage] = useState(1);
    const [min, setMinSlice] = useState(0);
    const [max, setMaxSlice] = useState(5);
    const [entryCount, setEntryCount] = useState(10);
    const [publishModal, setPublishModal] = useState(false);
    const {
        survey_details: customBmList = [],
        created_on = '',
        created_by = '',
        status = '',
        type = '',
        no_of_surveys = '',
        no_of_responses = '',
        name = '',
        filter_str = '',
        id = '',
    } = customBmDetail || {};

    const CREATE_CUSTOM_BM = [
        // {
        //     key: 'selectid',
        //     title: '',
        //     width: '4vw',
        // },
        {
            key: 'uniquesurveyreferencecode',
            title: 'OHI ID',
            width: '8vw',
        },
        {
            key: 'surveyclientname',
            title: 'Organisation Name',
            width: '15vw',
        },
        {
            key: 'totalcomplete',
            title: 'Complete Responses',
            width: '12vw',
        },
        {
            key: 'mckinseysectorlevel1',
            title: 'Sector Level 1',
            width: '15vw',
        },
        {
            key: 'mckinseysectorlevel2',
            title: 'Sector Level 2',
            width: '15vw',
        },
        {
            key: 'subregion1',
            title: 'Subregion (high level)',
            width: '15vw',
        },
        {
            key: 'subregion2',
            title: 'Subregion 2 (low level)',
            width: '15vw',
        },
        {
            key: 'country',
            title: 'Country',
            width: '8vw',
        },
        {
            key: 'quartile',
            title: 'Quartile',
            width: '6vw',
        },
        {
            key: 'region',
            title: 'Region',
            width: '10vw',
        },
        {
            key: 'bm_year',
            title: 'Year',
            width: '6vw',
        },
        {
            key: 'close_date',
            title: 'Closed Date',
            width: '15vw',
        },
        // {
        //     key: 'confirmitsurveyid',
        // },
        // {
        //     key: 'uniquesurveyreferencecode',
        // },
    ];

    const renderStatusIcon = status_type => {
        const { imgSrc } = STATUS_ICONS.find(({ statusType }) => statusType === status_type) || {};
        return (
            <span>
                <img src={imgSrc} alt="" />
            </span>
        );
    };

    const getFilterPreview = filtersArr => {
        const arr = [];
        filtersArr.forEach(({ filter_name, filter_items = [] }) => {
            filter_items
                .filter(({ isSelected }) => isSelected)
                .forEach(({ filter_item_name }) => {
                    arr.push(`${filter_name} - ${filter_item_name}`);
                });
        });
        return arr;
    };

    const renderfilterApplied = () => {
        const filterStr = getFilterArrFromFilterStr(defaultFilters, filter_str);
        const appliedFilters = getFilterPreview(filterStr);
        return (
            <div className="filterAppliedContent">
                <div className="filterDemo">
                    <div className="heading">{FILTERS_APPLIED}:</div>
                    {appliedFilters.map(elem => (
                        <div className="filterContent">
                            <div className="filterHighlight filterKey filterLab"> {elem} </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const lastPage = Math.ceil(customBmList.length / entryCount);

    return (
        <div className="customBmList customBmDetails">
            <Helmet>
                <title>{ANALYTIC_CUSTOM_BM_TEXT['1']} </title>
            </Helmet>
            <div className="bmDetailsHeader">
                <div className="breadCrumb">
                    <span data-testid="setScoreFromBuBtn" onClick={() => setTab(0)} className="greytext">
                        Your Benchmark
                    </span>{' '}
                    {' > '} <span>{name}</span>
                </div>
            </div>
            <div className="rightScorecardHeader">
                <div className="scorecardHeading">
                    <div className="sectionHeader">
                        <span>
                            <img src={Paper} alt="" />
                        </span>
                        Custom Benchmark Name: <b>{name}</b>
                    </div>
                    {/* <div className="nRes">{`${NUMBER_CAP_SURVEY_TEXT} (${NUMBER_TEXT}) : ${n_survey}`}</div> */}
                </div>
                <ul className="navScoreList">
                    {status === 'Draft' ? (
                        <Fragment>
                            <li onClick={() => setTab(5)}>
                                <img src={Edit} alt="" />
                                <span className="upperHead">EDIT</span>
                            </li>
                            <li onClick={() => setPublishModal(true)}>
                                <img src={Publish} alt="" />
                                <span className="upperHead">PUBLISH</span>
                            </li>
                        </Fragment>
                    ) : null}
                    <li data-testid="getPptBtn">
                        <img src={Down} alt="" />
                        <span className="upperHead">DOWNLOAD</span>
                    </li>
                    <li>
                        <img src={Detail} alt="" />
                        <span className="upperHead">DUPLICATE</span>
                    </li>
                </ul>
            </div>
            <div className="bmDetailsInfoCard">
                <div className="bmDetailsInfo">
                    <div className="title">No. of Surveys</div>
                    <div className="bold">{no_of_surveys}</div>
                </div>
                <div className="bmDetailsInfo">
                    <div className="title">No. of Responses</div>
                    <div className="bold">{no_of_responses}</div>
                </div>
                <div className="bmDetailsInfo">
                    <div className="title">Created On</div>
                    <div className="bold">{moment(created_on).format('DD/MM/YYYY')}</div>
                </div>
                <div className="bmDetailsInfo">
                    <div className="title">Created by</div>
                    <div className="bold">{created_by}</div>
                </div>
                <div className="bmDetailsInfo">
                    <div className="title">Type</div>
                    <div className="bold">{type}</div>
                </div>
                <div className="bmDetailsInfo status">
                    <div className="title">Status</div>
                    <div className="status">
                        <div
                            className={c({
                                draft: status === 'Draft',
                                awaiting: status === 'Awaiting Approval',
                            })}
                        >
                            {renderStatusIcon(status)}
                            {status}
                        </div>
                    </div>
                </div>
            </div>
            {renderfilterApplied()}
            <div className="optionWrapper">
                <div className="differentiatorToggle">
                    <ul className="leftNav">
                        <li>Survey List</li>
                    </ul>
                </div>
                <div className="rightOptions">
                    {/* <div>
                        <ul className="rightSideNav">
                            <li className="strong">{`Number of Surveys (n) = ${n_survey}`}</li>
                            <li
                                onClick={() => toggleFilterView(true)}
                                data-testid="filterButton"
                                className="filterButton"
                            >
                                <img src={filtersApplied ? ActiveFilter : FilterSvg} alt="" />
                                {FILTERS}
                            </li>
                        </ul>
                    </div> */}
                </div>
            </div>
            <div className="userReportsContent xScroll">
                <Accordion classes={{ root: 'accordionRoot' }} key="table" expanded>
                    <AccordionDetails classes={{ root: 'clearfix' }}>
                        <table className="userSection">
                            <tbody className="userContent">
                                <thead className="headerSection">
                                    {CREATE_CUSTOM_BM.map(({ key, title, width }) => (
                                        <th className="headerHeading" key={key} style={{ width }}>
                                            {title}
                                            {key === 'status' ? (
                                                <span className="infoIcon">
                                                    <Tooltip
                                                        // title="Ticket Journey: <br/> 1. To Do"
                                                        classes={{ popper: 'tooltip' }}
                                                        title={
                                                            <span>
                                                                Ticket Journey: <br /> 1. To Do <br /> 2. In Progress{' '}
                                                                <br /> 3. QA Ready <br /> 4. Code Review <br /> 5. Done{' '}
                                                            </span>
                                                        }
                                                    >
                                                        <span />
                                                        {/* <img src={InfoIcon} alt="" /> */}
                                                    </Tooltip>
                                                </span>
                                            ) : null}
                                        </th>
                                    ))}
                                </thead>

                                {!!customBmList.length &&
                                    customBmList.slice((page - 1) * entryCount, page * entryCount).map(obj => {
                                        const { close_date = '' } = obj || {};
                                        return (
                                            <tr key={close_date} className="userDataSection">
                                                {CREATE_CUSTOM_BM.map(({ key, width }) => {
                                                    return (
                                                        <td className="userTableContent" key={key} style={{ width }}>
                                                            {key === 'close_date'
                                                                ? moment(obj[key]).format('DD-MMM-YYYY')
                                                                : obj[key]}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                {!customBmList.length ? (
                                    <div className="emptyList">
                                        <div>
                                            <h3>No survey list available</h3>
                                            <img src={CloudImage} alt="" />
                                        </div>
                                    </div>
                                ) : null}
                            </tbody>
                        </table>
                    </AccordionDetails>
                </Accordion>
                {customBmList.length > entryCount ? (
                    <div className="pagination">
                        <span
                            onClick={() => {
                                if (min === 0) return null;
                                setMinSlice(min - 5);
                                setMaxSlice(max - 5);
                                return null;
                            }}
                            className={c({ inActive: page === 1 }, 'arrowBtn')}
                        >
                            <img src={ArrowDoubleBack} alt="" />
                        </span>
                        <span onClick={() => setPage(page - 1)} className={c({ inActive: page === 1 }, 'arrowBtn')}>
                            <img src={ArrowBackSm} alt="" />
                        </span>
                        {Array(lastPage)
                            .fill()
                            .slice(min, max)
                            .map((_, index) => {
                                return (
                                    <span
                                        key={index + 1}
                                        className={c({ activePage: page === index + 1 })}
                                        onClick={() => setPage(index + 1)}
                                    >
                                        {index + 1}
                                    </span>
                                );
                            })}
                        <span
                            onClick={() => setPage(page + 1)}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowForward} alt="" />
                        </span>
                        <span
                            onClick={() => {
                                if (min + 5 > lastPage) return null;
                                setMinSlice(min + 5);
                                setMaxSlice(max + 5);
                                return null;
                            }}
                            className={c({ inActive: page === lastPage }, 'arrowBtn')}
                        >
                            <img src={ArrowDoubleForward} alt="" />
                        </span>
                        <span className="resultsPerPage">
                            Results Per Page{' '}
                            <span>
                                <Select
                                    IconComponent={KeyboardArrowDownSharp}
                                    value={entryCount}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        },
                                        transformOrigin: {
                                            vertical: 'top',
                                            horizontal: 'left',
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                    onChange={({ target }) => {
                                        setEntryCount(target.value);
                                    }}
                                >
                                    {[10, 11, 12].map(pageItem => {
                                        return (
                                            <MenuItem
                                                classes={{ root: 'demographicOptions' }}
                                                key={pageItem}
                                                value={pageItem}
                                            >
                                                {pageItem}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </span>
                        </span>
                    </div>
                ) : null}
            </div>
            {publishModal ? (
                <PublishModal
                    setView={setPublishModal}
                    sendPublishRequest={sendCustomBmRequest}
                    customBmId={id}
                    customBmName={name}
                />
            ) : null}
        </div>
    );
}

CustomBmDetails.propTypes = {
    defaultSettings: PropTypes.func.isRequired,
    setTab: PropTypes.func.isRequired,
    customBmDetail: PropTypes.object.isRequired,
    defaultFilters: PropTypes.array.isRequired,
    sendCustomBmRequest: PropTypes.func.isRequired,
};

export default CustomBmDetails;
