import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Checkbox, Dialog, FormControlLabel, List, ListItem, Popover, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
// import Inf#oLarge from '../assets/T-warning.svg';
import SearchIcon from '../../../assets/Magnifying.svg';
import './index.scss';
import { getSurveyList } from '../apis';

const PublishModal = ({ setView, sendPublishRequest, customBmId, customBmName }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [search, setSearch] = useState('');
    const [surveyList, setSurveyList] = useState([]);
    const inputRef = useRef(null);
    const [surveys, selectSurveys] = useState([]);

    const searchSurvey = async val => {
        try {
            setAnchorEl(inputRef.current);
            const { data } = await getSurveyList({ q: val, year: 2024 });
            const { data: list = [] } = data || {};
            setSurveyList(list);
            setAnchorEl(inputRef.current);
        } catch (err) {
            setAnchorEl(null);
        }
    };
    function debounce(func, wait) {
        let timeout;

        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };

            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    // const debouncedFetchSuggestions = val => debounce(searchSurvey(val), 2000);

    const handleSearch = e => {
        const { value } = e.target;
        setSearch(value);
        if (value) {
            debounce(searchSurvey(value), 2000);
        }
        setAnchorEl(inputRef.current);
    };

    useEffect(() => {
        if (search) {
            searchSurvey(search);
        }
    }, [search]);

    const handleChange = ({ checked }, value) => {
        const arr = cloneDeep(surveys);
        if (checked) {
            arr.push(value);
            selectSurveys(arr);
        } else {
            const i = arr.findIndex(id => id === value);
            selectSurveys([...arr.slice(0, i), ...arr.slice(i + 1)]);
        }
    };
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);

    const publishRequest = () => {
        sendPublishRequest({
            id: customBmId,
            payload: {
                is_public: false,
                attached_ohi_ids: surveys,
            },
        });
    };

    return (
        <Dialog
            open
            disableEscapeKeyDown
            disableBackdropClick
            maxWidth="md"
            classes={{
                root: 'publishDialogRoot',
            }}
        >
            <section className="infoContent">
                {/* <div className="infoLarge">
                    <img src={InfoLarge} alt="" />
                </div> */}
                <h2>Send publish request</h2>
                <div className="para">
                    <div>
                        Would you like to publish “Custom Benchmark 1”, send request to OHI Helpdesk. You will receive
                        an update on you email once it’s approved.
                    </div>
                    <div>
                        <b>Please note :</b> You can add OHI ID’s and make the created custom benchmark added to those
                        ID’s.
                    </div>
                </div>
                <div>Attach this {customBmName} to below mentioned surveys.</div>
                <div className="searchHead">
                    <img src={SearchIcon} alt="" className="searchIcon" />
                    <TextField
                        fullWidth
                        value={search}
                        placeholder="Search"
                        inputRef={inputRef}
                        onChange={e => handleSearch(e)}
                    />
                    {surveyList.length ? (
                        <Popover
                            id="search"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            // getContentAnchorEl={null}
                            classes={{
                                root: 'popoverRoot',
                            }}
                        >
                            {surveyList.map(({ survey_id }) => {
                                return (
                                    <List component="div" disablePadding key={survey_id}>
                                        <ListItem>
                                            <FormControlLabel
                                                classes={{
                                                    root: 'optionItem analyticsOptionItem',
                                                }}
                                                control={
                                                    <Checkbox
                                                        checked={surveys.includes(survey_id)}
                                                        onChange={({ target }) => handleChange(target, survey_id)}
                                                        // disabled={}
                                                        name={survey_id}
                                                        // inputProps={{
                                                        //     'data-testid': `ChildCheck${c}`,
                                                        // }}
                                                    />
                                                }
                                                label={survey_id}
                                            />
                                        </ListItem>
                                    </List>
                                );
                            })}
                        </Popover>
                    ) : null}
                    {/* <img src={CloseIcon} alt="" className="closeIcon" /> */}
                </div>
            </section>
            <section className="btnContent clearfix activeButtons">
                <Fragment>
                    <div
                        data-testid="detailButton"
                        className="detailedBtn aciveDetailBtn"
                        onClick={() => {
                            setView(false);
                        }}
                    >
                        Cancel
                    </div>
                    <div data-testid="compareButton" className="compareBtn activeCompareBtn" onClick={publishRequest}>
                        Send request
                    </div>
                </Fragment>
            </section>
        </Dialog>
    );
};

PublishModal.propTypes = {
    setView: PropTypes.func.isRequired,
    sendPublishRequest: PropTypes.func.isRequired,
    customBmId: PropTypes.number.isRequired,
    customBmName: PropTypes.string.isRequired,
};

export default PublishModal;
