import { ArrowBack, KeyboardArrowDownSharp } from '@material-ui/icons';
import c from 'classnames';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
    Accordion,
    AccordionDetails,
    Button,
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem,
    Select,
} from '@material-ui/core';
import KeyboardArrowDownSharpIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import DisclaimerWarning from '../assets/DisclaimerWarning1.svg';
import CloudImage from '../assets/InsufficientData1.svg';
import ArrowBackward from '../../Diagnose/assets/Small-left.svg';
import ArrowForward from '../../Diagnose/assets/Small-right.svg';
import ArrowDoubleBack from '../../Diagnose/assets/Double-arrow-left.svg';
import ArrowDoubleForward from '../../Diagnose/assets/Double-arrow-right.svg';
import { getStaticText } from '../../../utils/constants';
import CustomBmFilter from '../../../components/CustomBmFilterSelect/CustomBmFilter';
// import FilterBar from '../../../components/FilterBarAnalytics';
import './index.scss';
import getFilterString from '../assets/functions';
import LoaderToast from '../../../components/CustomToast/LoaderToast';

function CreateCustomBenchmark({
    defaultSettings,
    setTab,
    // appliedFilters,
    getCustomBmSurveyList,
    customBmSurveyList,
    filter,
    setFilters,
    saveCustomBmDraft,
    // customBmMsg,
    data,
    setData,
    checkNameExist,
    checkNameMsg,
    isNameAvailable,
    surveyListLoading,
    defaultYear,
    defaultFilters,
}) {
    const { lang = '1033' } = defaultSettings || {};
    const staticText = getStaticText(lang);
    const { SITE_TEXT } = staticText;
    const { DISCLAIMER } = SITE_TEXT || {};
    const [page, setPage] = useState(1);
    const [min, setMinSlice] = useState(0);
    const [max, setMaxSlice] = useState(5);
    const [entryCount, setEntryCount] = useState(10);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const { survey_ids: selected = [], name: customBmName = '', year, type: customBmType = '', filter_str } =
        data || {};
    const generateList = () => {
        const params = getFilterString(filter);
        getCustomBmSurveyList(params);
        setData({
            ...data,
            filter_str: params,
        });
        setTab(3);
    };
    const CREATE_CUSTOM_BM = [
        {
            key: 'selectid',
            title: '',
            width: '4vw',
        },
        {
            key: 'uniquesurveyreferencecode',
            title: 'OHI ID',
            width: '8vw',
        },
        {
            key: 'surveyclientname',
            title: 'Organisation Name',
            width: '15vw',
        },
        {
            key: 'totalcomplete',
            title: 'Complete Responses',
            width: '12vw',
        },
        {
            key: 'mckinseysectorlevel1',
            title: 'Sector Level 1',
            width: '15vw',
        },
        {
            key: 'mckinseysectorlevel2',
            title: 'Sector Level 2',
            width: '15vw',
        },
        {
            key: 'subregion1',
            title: 'Subregion (high level)',
            width: '15vw',
        },
        {
            key: 'subregion2',
            title: 'Subregion 2 (low level)',
            width: '15vw',
        },
        {
            key: 'country',
            title: 'Country',
            width: '8vw',
        },
        {
            key: 'quartile',
            title: 'Quartile',
            width: '6vw',
        },
        {
            key: 'region',
            title: 'Region',
            width: '10vw',
        },
        {
            key: 'bm_year',
            title: 'Year',
            width: '6vw',
        },
        {
            key: 'close_date',
            title: 'Closed Date',
            width: '15vw',
        },
        // {
        //     key: 'confirmitsurveyid',
        // },
        // {
        //     key: 'uniquesurveyreferencecode',
        // },
    ];
    const customBmList = customBmSurveyList;
    const lastPage = Math.ceil(customBmList.length / entryCount);

    const handleChange = ({ name, value }) => {
        const newData = cloneDeep(data);
        newData[name] = value;
        setData(newData);
    };

    const handleCheck = (key, isChecked) => {
        let ohi = [...selected];
        if (isChecked) {
            ohi.push(key);
        } else {
            const i = ohi.findIndex(id => id === key);
            ohi = [...ohi.slice(0, i), ...ohi.slice(i + 1)];
        }
        setData({
            ...data,
            survey_ids: ohi,
        });
    };

    const handleCheckAll = isChecked => {
        const list = customBmList.map(({ id }) => id);
        if (isChecked) {
            setData({
                ...data,
                survey_ids: list,
            });
        } else {
            setData({
                ...data,
                survey_ids: [],
            });
        }
    };

    const validatePayload = () => {
        const { name, type, year: yr, filter_str: str, survey_ids } = data;
        if (!name || !type || !yr || !str || !survey_ids.length || !isNameAvailable) {
            return false;
        }
        return true;
    };

    const createDraft = () => {
        const isValid = validatePayload();
        if (isValid) {
            saveCustomBmDraft(data);
            setTab(0);
            setFilters(defaultFilters);
        }
    };

    const getFilterPreview = filtersArr => {
        const arr = [];
        filtersArr.forEach(({ filter_name, filter_items = [] }) => {
            filter_items
                .filter(({ isSelected }) => isSelected)
                .forEach(({ filter_item_name }) => {
                    arr.push(`${filter_name} - ${filter_item_name}`);
                });
        });
        return arr;
    };

    useEffect(() => {
        // setData({
        //     ...data,
        //     filter_str: getFilterString(filter),
        // });
        const filterApplied = getFilterPreview(filter);
        setAppliedFilters(filterApplied);
    }, [filter]);

    // useEffect(() => {}, [min, max]);

    // useEffect(() => {
    //     const isValid = validatePayload();
    //     if (isValid) {
    //         setActive(true);
    //     } else {
    //         setActive(false);
    //     }
    // }, [data]);

    const renderfilterApplied = () => {
        return (
            <div className="filterAppliedContent">
                <div className="filterDemo">
                    {appliedFilters.map(elem => (
                        <div className="filterContent">
                            <div className="filterHighlight filterKey filterLab"> {elem} </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const updateFilters = newF => {
        setData({
            ...data,
            filter_str: getFilterString(newF),
        });
        setFilters(newF);
        setIsOpen(false);
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const checkAvailability = () => {
        if (customBmName) {
            checkNameExist({ id: 0, name: customBmName });
        }
    };

    return (
        <div className="createCustomBm clearfix" data-testid="buBoardContent">
            <Helmet>
                <title>Create Custom benchmark</title>
            </Helmet>
            <div className="pageHeader" id="pageHeader">
                <div className="breadCrumb">
                    <span data-testid="setScoreFromBuBtn" onClick={() => setTab(0)}>
                        Custom Benchmark
                    </span>{' '}
                    {' > '} Custom Benchmark
                </div>
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">
                            <span data-testid="setBackFromBuBtn" onClick={() => setTab(0)}>
                                <ArrowBack />
                                Create Custom Benchmark
                            </span>
                        </div>
                    </div>
                    <ul className="navScoreList">
                        <li>
                            {/* <img src={Down} alt="" /> */}
                            <span className="lineHead upperHead">RESET</span>
                        </li>
                        <div className="btn">
                            <li onClick={createDraft} className={c({ active: validatePayload() })}>
                                Create Draft
                            </li>
                            <li className={c({ active: validatePayload() })}>Publish Benchmark</li>
                        </div>
                    </ul>
                </div>
            </div>
            <div className="createBmUserInput">
                <div className="disclaimerContent">
                    <div className="disclaimerNote">
                        <span>
                            <img src={DisclaimerWarning} alt="" />
                            {DISCLAIMER} :{' '}
                        </span>
                        <span>
                            Identifiable information about the organizations in the OHI database CANNOT be shared with
                            clients and CSTs. This includes client names, and any information that breaches client
                            confidentiality even without stating theclient name (e.g., Telecom organization in Canada).
                        </span>
                    </div>
                </div>
                <div className="inputSection">
                    <h3>Basic Information</h3>
                    <div className="inputRow">
                        <div>
                            <div className="title">
                                Benchmark Name<sup>*</sup>
                            </div>
                            <Input
                                placeholder="Enter Benchmark name"
                                name="name"
                                value={customBmName}
                                onChange={({ target }) => handleChange(target)}
                                onMouseLeave={checkAvailability}
                            />
                            {checkNameMsg ? (
                                <div className={c({ available: isNameAvailable }, 'checkNameExist')}>
                                    {isNameAvailable ? (
                                        <span>{checkNameMsg}</span>
                                    ) : (
                                        <span>
                                            <img src={DisclaimerWarning} alt="" />
                                            {checkNameMsg}
                                        </span>
                                    )}
                                </div>
                            ) : null}
                        </div>
                        <div>
                            <div className="title">
                                Benchmark Type<sup>*</sup>
                            </div>
                            <Select
                                IconComponent={KeyboardArrowDownSharpIcon}
                                // value={type_of_bug}
                                // variant="outlined"
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                name="type"
                                onChange={({ target }) => handleChange(target)}
                                value={customBmType}
                            >
                                <MenuItem classes={{ root: 'demographicOptions' }} value="" disabled>
                                    <em>Select Benchmark type</em>
                                </MenuItem>
                                {[
                                    { label: 'Flagpole', code: 'flagpole' },
                                    { label: 'Benchmark', code: 'benchmark' },
                                ].map(({ label, code }) => {
                                    return (
                                        <MenuItem
                                            classes={{ root: 'demographicOptions' }}
                                            key={code + label}
                                            value={code}
                                        >
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="inputSection">
                    <h3>Filter</h3>
                    <div className="inputRow">
                        <div>
                            <div className="title">
                                Benchmark Year<sup>*</sup>
                            </div>
                            <Select
                                IconComponent={KeyboardArrowDownSharpIcon}
                                value={defaultYear}
                                // variant="outlined"
                                displayEmpty
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                label="Select Benchmark Filter"
                                name="year"
                                onChange={({ target }) => handleChange(target)}
                            >
                                <MenuItem classes={{ root: 'demographicOptions' }} value="">
                                    <em>Select Benchmark year</em>
                                </MenuItem>
                                {[{ code: defaultYear, label: defaultYear }].map(({ label, code }) => {
                                    return (
                                        <MenuItem
                                            classes={{ root: 'demographicOptions' }}
                                            key={code + label}
                                            value={code}
                                        >
                                            {label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                        <div>
                            <div className="title">
                                Other Filter<sup>*</sup>
                            </div>
                            <Select
                                IconComponent={KeyboardArrowDownSharpIcon}
                                // value={type_of_bug}
                                // variant="outlined"
                                displayEmpty
                                MenuProps={{
                                    classes: { paper: 'customBm' },
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    // PaperProps: {
                                    //     style: {
                                    //         maxHeight: 600,
                                    //     },
                                    // },
                                    // disableScrollLock: true,
                                    // getContentAnchorEl: null,
                                }}
                                name=""
                                label="Select Benchmark Filter"
                                open={isOpen}
                                onOpen={handleOpen}
                                onClose={() => setIsOpen(false)}
                            >
                                <CustomBmFilter
                                    filters={filter}
                                    defaultSettings={defaultSettings}
                                    updateFilters={newF => updateFilters(newF)}
                                />
                            </Select>
                            {/* <div>{renderfilterApplied}</div> */}
                        </div>
                    </div>
                </div>
                <div className="inputSection">
                    <div className="inputRow">
                        <div /> <div>{renderfilterApplied()}</div>
                    </div>
                </div>
                <div className="btnSection">
                    <div className="btnRow">
                        <Button
                            onClick={generateList}
                            className={c({
                                active: !!customBmName && !!customBmType && !!year && !!filter_str && isNameAvailable,
                            })}
                        >
                            Generate List
                        </Button>
                    </div>
                </div>
            </div>
            <div className="tableContainer">
                <div className="rightScorecardHeader">
                    <div className="scorecardHeading">
                        <div className="mainHeading">Select from the survey list</div>
                        <div className="nRes">{`${selected.length} of ${customBmList.length} surveys are selected`}</div>
                    </div>
                </div>
                <div className="userReportsContent xScroll">
                    <Accordion classes={{ root: 'accordionRoot' }} key="table" expanded>
                        <AccordionDetails classes={{ root: 'clearfix' }}>
                            <table className="userSection">
                                <tbody className="userContent">
                                    <thead className="headerSection">
                                        {CREATE_CUSTOM_BM.map(({ key, title, width }) => (
                                            <th className="headerHeading" key={key} style={{ width }}>
                                                {title}
                                                {key === 'selectid' ? (
                                                    <FormControlLabel
                                                        classes={{
                                                            root: c('optionItem', {
                                                                // width80: optionNo !== '',
                                                            }),
                                                        }}
                                                        control={
                                                            <Checkbox
                                                                onChange={({ target }) =>
                                                                    // handleCheck(obj.id, target.checked)
                                                                    handleCheckAll(target.checked)
                                                                }
                                                                // disabled={}
                                                                // inputProps={{
                                                                //     'data-testid': `ChildCheck${childLabel}`,
                                                                // }}
                                                            />
                                                        }
                                                    />
                                                ) : null}
                                            </th>
                                        ))}
                                    </thead>

                                    {!!customBmList.length &&
                                        customBmList.slice((page - 1) * entryCount, page * entryCount).map(obj => {
                                            const { close_date = '' } = obj || {};
                                            return (
                                                <tr key={close_date} className="userDataSection">
                                                    {CREATE_CUSTOM_BM.map(({ key, width }) => {
                                                        return (
                                                            <td
                                                                className="userTableContent"
                                                                key={key}
                                                                style={{ width }}
                                                            >
                                                                {key === 'close_date'
                                                                    ? moment(obj[key]).format('DD-MMM-YYYY')
                                                                    : obj[key]}
                                                                {key === 'selectid' ? (
                                                                    <FormControlLabel
                                                                        classes={{
                                                                            root: c('optionItem', {
                                                                                // width80: optionNo !== '',
                                                                            }),
                                                                        }}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={selected.includes(obj.id)}
                                                                                onChange={({ target }) =>
                                                                                    handleCheck(obj.id, target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                    />
                                                                ) : null}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    {!customBmList.length ? (
                                        <div className="emptyList">
                                            <div>
                                                <h3>No survey list available</h3>
                                                <img src={CloudImage} alt="" />
                                            </div>
                                        </div>
                                    ) : null}
                                    {surveyListLoading ? <LoaderToast /> : null}
                                </tbody>
                            </table>
                        </AccordionDetails>
                    </Accordion>
                    {customBmList.length > entryCount ? (
                        <div className="pagination">
                            <span
                                onClick={() => {
                                    if (min - 4 <= 0) return null;
                                    setPage(min - 4);
                                    setMaxSlice(max - 5);
                                    setMinSlice(min - 5);
                                    return null;
                                }}
                                className={c({ inActive: page === 1 }, 'arrowBtn')}
                            >
                                <img src={ArrowDoubleBack} alt="" />
                            </span>
                            <span
                                onClick={() => {
                                    setPage(page - 1);
                                    if (page - 1 <= min) {
                                        setMaxSlice(max - 5);
                                        setMinSlice(min - 5);
                                    }
                                }}
                                className={c({ inActive: page === 1 }, 'arrowBtn')}
                            >
                                <img src={ArrowBackward} alt="" />
                            </span>
                            {Array(lastPage)
                                .fill(1)
                                .map((_, i) => i + 1)
                                .slice(min, max)
                                .map(index => {
                                    return (
                                        <span
                                            key={index + 1}
                                            className={c({ activePage: page === index })}
                                            onClick={() => {
                                                setPage(index);
                                            }}
                                        >
                                            {index}
                                        </span>
                                    );
                                })}
                            <span
                                onClick={() => {
                                    setPage(page + 1);
                                    if (page >= max) {
                                        setMaxSlice(max + 5);
                                        setMinSlice(min + 5);
                                    }
                                }}
                                className={c({ inActive: page === lastPage }, 'arrowBtn')}
                            >
                                <img src={ArrowForward} alt="" />
                            </span>
                            <span
                                onClick={() => {
                                    if (min + 5 > lastPage) return null;
                                    setPage(min + 6);
                                    setMinSlice(min + 5);
                                    setMaxSlice(max + 5);
                                    return null;
                                }}
                                className={c({ inActive: page === lastPage }, 'arrowBtn')}
                            >
                                <img src={ArrowDoubleForward} alt="" />
                            </span>
                            <span className="resultsPerPage">
                                Results Per Page{' '}
                                <span>
                                    <Select
                                        IconComponent={KeyboardArrowDownSharp}
                                        value={entryCount}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        onChange={({ target }) => {
                                            setEntryCount(target.value);
                                        }}
                                    >
                                        {[10, 11, 12].map(pageItem => {
                                            return (
                                                <MenuItem
                                                    classes={{ root: 'demographicOptions' }}
                                                    key={pageItem}
                                                    value={pageItem}
                                                >
                                                    {pageItem}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </span>
                            </span>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

CreateCustomBenchmark.propTypes = {
    defaultSettings: PropTypes.object.isRequired,
    setTab: PropTypes.func.isRequired,
    getCustomBmSurveyList: PropTypes.func.isRequired,
    customBmSurveyList: PropTypes.array.isRequired,
    filter: PropTypes.array.isRequired,
    setFilters: PropTypes.func.isRequired,
    saveCustomBmDraft: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired,
    defaultYear: PropTypes.number.isRequired,
    checkNameExist: PropTypes.func.isRequired,
    checkNameMsg: PropTypes.string.isRequired,
    isNameAvailable: PropTypes.bool.isRequired,
    surveyListLoading: PropTypes.bool.isRequired,
    defaultFilters: PropTypes.array.isRequired,
};

export default CreateCustomBenchmark;
